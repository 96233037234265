// Bootstrap components
import { Container, Row, Col, Form, /*FormLabel,*/ FormControl, Button/*, Alert*/ } from 'react-bootstrap';


//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from "react-router-dom";
import AppComms from '../AppComms';

// Models
import Business from '../shared/models/Business.mjs';
import ContactInformation from '../shared/models/ContactInformation.mjs';
import PhysicalAddress from '../shared/models/PhysicalAddress.mjs';
import DigitalAddress from '../shared/models/DigitalAddress.mjs';

import './Companies.css';

// Common components
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import SimplePanel from '../components/panels/SimplePanel';
import CompanyPanel from '../components/panels/CompanyPanel';

class Companies extends React.Component {
	
    constructor(props) {
        super(props);
        this.fetchTimeout = null;
        this.state = {validated: '', error: '', working: true, searchResults: {}, searchCompanies: [], zip: '', term: '', type: ''};
        this.form = React.createRef();
		this.zipUpdate = this.zipUpdate.bind(this);
		this.typeUpdate = this.typeUpdate.bind(this);
		this.termUpdate = this.termUpdate.bind(this);
    }

    componentDidMount(prevProps) {
        clearTimeout( this.fetchTimeout );
        this.fetchTimeout = setTimeout( function() {
            this.fetchCompanies();
        }.bind(this), 50);
    }

	zipUpdate(event) {
		this.setState({zip: event.target.value});
	}
	typeUpdate(event) {
        this.setState({type: event.target.value});
        clearTimeout( this.fetchTimeout );
        this.fetchTimeout = setTimeout( function() {
            this.fetchCompanies();
        }.bind(this), 50);
	}
	termUpdate(event) {
		this.setState({term: event.target.value});
	}
	
	render(location) {
		return (
			<Content>
                <ScrollToTop/>
                <Helmet title="You Are Doing Great Profile" />

                <Container className="searchContainer">
                    <Row noGutters="true">
                        <Col>
                            <SimplePanel title="Companies">
                                <Form ref={this.form} noValidate validated={this.state.validated} onSubmit={this.searchActual.bind(this)}>
                                    <Form.Row>
                                        <Form.Group as={Col} xs="12" md="12" lg="5" controlId="validationCustom02">
                                            <FormControl
                                                required
                                                type="text"
                                                placeholder="Search (e.g. Surrogacy, IVF) or company/doctor name"
                                                value={this.state.term}
                                                onChange={this.termUpdate}>
                                            </FormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="4" md="4" lg="3" controlId="validationCustom01">
                                            <FormControl
                                                required
                                                type="text"
                                                placeholder="Zip code or city"
                                                value={this.state.zip}
                                                onChange={this.zipUpdate}>
                                            </FormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="8" md="8" lg="3" controlId="validationCustom03">
                                            <FormControl as="select"
                                                required
                                                value={this.state.type}
                                                onChange={this.typeUpdate}>
                                                <option value="">All categories</option>
                                                <option value="fertility">Fertility services</option>
                                                <option value="adoptionservices">Adoption & Surrogacy</option>
                                                <option value="obgyn">Obstetricians & Gynecologists</option>
                                                <option value="legalservices">Legal services</option>
                                            </FormControl>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="12" md="12" lg="1" controlId="validationCustom02">
                                            <Button type="submit" variant="primary" style={{float:"right",textAlign:"right"}}>
                                                {false && "Search"}
                                                {true && <div style={{"WebkitTransform": "rotate(-45deg)",
                                                        "MozTransform": "rotate(-45deg)", 
                                                            "OTransform":"rotate(-45deg)",
                                                                "transform":"rotate(-45deg)"}}>&#9906;</div>}
                                            </Button>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </SimplePanel>
                        </Col>
                    </Row>

                    <Row hidden={this.state.working || !(this.state.searchCompanies||[]).length} noGutters="true">
                        <Col sm="12">
                            <h3 style={{"margin":"6px 10px 12px"}}>Nearby results</h3>
                        </Col>
                    </Row>

                    <Row hidden={this.state.working || !(this.state.searchCompanies||[]).length} noGutters="true">
                        {(this.state.searchCompanies||[]).map(item => 
                            <Col key={item.id} sm="12" xl="6">
                                <CompanyPanel business={item}/>
                            </Col>
                        )}
                    </Row>
                    
                    <div hidden>
                        <lottie-player src="https://assets3.lottiefiles.com/datafiles/5mEmfhAxpgrsoFG/data.json" background="transparent" speed="0.5" style={{"maxWidth":"100%","width":"300px","height":"75px","margin":"15vh auto 0"}} loop autoplay></lottie-player>
                    </div>
                
                    <Row hidden={(this.state.searchCompanies||[]).length} noGutters="true">
                        <Col>
                            <div style={{'textAlign': 'center', 'padding': '5vh 0'}}>
                                <lottie-player src="https://assets6.lottiefiles.com/temp/lf20_LJK4oD.json" background="transparent" speed="1.5" style={{"width": "150px", "height": "150px", "margin": "0 auto"}} loop autoplay></lottie-player>

                                <div hidden={!this.state.working}>
                                    <h2 style={{'paddingBottom': '20px'}}>Searching...</h2>
                                </div>

                                <div hidden={this.state.working || (this.state.searchCompanies||[]).length}>
                                    <h2 style={{'paddingBottom': '20px'}}>Sorry, we didn't find any results</h2>
                                    <p>
                                        Search tips:<br/>
                                        <br/>
                                            - Check your spelling and try again<br/>
                                            - Try a simpler search term<br/>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    
                </Container>
			</Content>
  	    );
    }

	async fetchCompanies() {
		try {
            this.setState({working: true});
            this.setState({searchResults: {}, searchCompanies: []});
            var data = await AppComms.get(`company/search?zip=${this.state.zip||''}&type=${this.state.type||''}&name=${this.state.term||''}`, null);
            
            // TODO: Rework to generic parser
            //AppComms.parseApiItems()

			// Create objects
			var searchResults = {};
			var searchCompanies = [];
			Object.keys(data.items).forEach(className => {
				data.items[className].forEach(item => {
					var obj = null;
					switch( className ) {
						case "Business": obj = new Business(item); searchCompanies.push(obj); break;
						case "ContactInformation": obj = new ContactInformation(item); break;
						case "PhysicalAddress": obj = new PhysicalAddress(item); break;
						case "DigitalAddress": obj = new DigitalAddress(item); break;
						default: break;
					}
					if ( obj ) {
                        //obj.init(item);
						searchResults[item.id] = obj;
					}
				});
            });

            // Unpack objects (turn IDs into objects) and cache in local storage
            Object.keys(searchResults).forEach(id => {
				searchResults[id].unpack(searchResults);
            });
            
            this.setState({searchResults, searchCompanies});
            this.setState({working: false});
		} catch(e) {
            console.error(e);
            this.setState({searchResults: {}, searchCompanies: []});
            this.setState({working: false});
		}
	}

	searchActual(event) {
		event.preventDefault();
        event.stopPropagation();

        this.fetchCompanies();
    }
}

export default withRouter(Companies);