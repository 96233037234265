// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { Link, withRouter } from "react-router-dom";

// Common components
import AppContext from '../AppContext';
import { marshallCognitoProfile } from '../AppProvider';
import './Dashboard.css';
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import ProcessPanel from '../components/panels/ProcessPanel';
//import TaskPanel from '../components/panels/TaskPanel';
import SchedulePanel from '../components/panels/SchedulePanel';
import TasksFragment from '../fragments/TasksFragment';
import ActivityFragment from '../fragments/ActivityFragment';

//import AWS from 'aws-sdk';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

class Dashboard extends React.Component {
	
  constructor(props) {
		super(props);

		this.sessionGate.bind(this)();
	}
	
	render(location) {
		return (
			<AppContext.Consumer>
				{context => (
					<Content>
						<Helmet title="You Are Doing Great Dashboard" />
						<ScrollToTop/>
						
						<Container className="DashboardContainer">
							<Row noGutters>
								<Col style={{'textAlign':'center', 'display':'flex', 'flexDirection': 'row', 'alignContent': 'stretch', 'flexFlow': 'row wrap'}}>
									<ProcessPanel type="Donor" percent="100"></ProcessPanel>
									<ProcessPanel type="IVF" percent="100"></ProcessPanel>
									<ProcessPanel type="Surrogacy" percent="25"></ProcessPanel>
								</Col>
							</Row>
							<Row noGutters>
								<Col>
									<TasksFragment context={context} title="My tasks" limit="10">
										<p hidden className="compact right"><Link to="/task" className="primary">See all tasks</Link></p>
									</TasksFragment>
									<SchedulePanel/>
								</Col>
								<Col sm="12" md="12" lg="6">
									<ActivityFragment context={context} title="Recent activity" limit="10">
										<p className="compact right"><Link to="/activity" className="primary">See all activity</Link></p>
									</ActivityFragment>
								</Col>
							</Row>
						</Container>
					</Content>
				)}
			</AppContext.Consumer>
		);
	}

	sessionGate() {
		var that = this;
		var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
		var cognitoUser = userPool.getCurrentUser();

		if (cognitoUser == null) {
			that&&that.props.history.push(`/login`)
		} else {
			cognitoUser.getSession(function(err, session) {
				if (err) {
					if ( err.code === "UserNotConfirmedException" ) {
						that&&that.props.history.push(`/unconfirmed`); 
					} else {
						debugger;
						that&&that.props.history.push(`/login`); 
					}
				} else if ( !session.isValid() ) {
					debugger;
					that&&that.props.history.push(`/login`)
				} else {
					cognitoUser&&cognitoUser.getUserAttributes(function(err, result) {
						if (err) {
							//that&&that.props.history.push(`/login`);
						} else {
							var profile = marshallCognitoProfile(result);
							if ( !profile['custom:onboarded'] ) {
								//that&&that.props.history.push(`/onboarding`);
							}
						}
					});
				}
			});
		}
	}
};

export default withRouter(Dashboard);