// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import { withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";

import './SimplePanel.css';

function SimplePanel({ location, ...props }) {
	return (
		<div className={`SimplePanelWrapper`}>
			<div className={`SimplePanelInner`} style={{backgroundColor:props.color||'#FFFFFF'}}>
				<Container fluid className={`SimplePanelContainer ${props.className||''}`}>
					<Row hidden={!props.title} className="titlerow">
						<Col>
							<h2 className="title">{props.title}</h2>
						</Col>
					</Row>
					<Row hidden={!props.header} className="headerrow">
						{props.header}
					</Row>
					<Row className="childrow">
						<Col>
							{props.children}
						</Col>
					</Row>
					<Row hidden={!props.footer} className="footerrow">
						{props.footer}
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default withRouter(SimplePanel);