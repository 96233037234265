// Bootstrap components
import { Container, Row, Col, Popover, ButtonToolbar, OverlayTrigger, Button } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import Helmet from 'react-helmet';
import Avatar from 'react-avatar';

// Common components
import AppComms from '../AppComms';
import AppProvider from '../AppProvider';
import './Header.css';
import MessageFragment from '../fragments/MessagesFragment';

// Resources
import logo from '../IconSurrojoy.svg';

const HeaderPaths = ["/dashboard", "/profile", "/activity", "/messages", "/timeline", "/companies"];
const ProfilePaths = ["/onboarding", "/dashboard", "/profile", "/activity", "/messages", "/timeline", "/companies"];

function ProfileMenu(profile) {
	return (
		<Popover className="ProfilePopup" id="ProfilePopup">
			<Popover.Title as="h3">Hi {((profile||{}).getName&&profile.getFirstName()) || ''}!</Popover.Title>
			<Popover.Content>
					<ul>
						<Link to="/profile"><li>Profile</li></Link>
						<Link to="/logout"><li>Logout</li></Link>
					</ul>
			</Popover.Content>
		</Popover>
	);
}
function MessageMenu(messages) {
	return (
		<Popover className="UnseenPopup" id="UnseenPopup">
			<Popover.Title as="h3">Unread messages</Popover.Title>
			<Popover.Content>
				<MessageFragment size="sm" link messages={messages} />
				<div className="MessageLink">
					<Link className="link" to="/messages">Open messages</Link>
				</div>
			</Popover.Content>
		</Popover>
	);
}

class Header extends React.Component {
	static profileCache = null;

	constructor(props) {
		super(props);

		this.state = { 
			profile: null,
			profileObjects: Header.profileCache||{},
			unseenObjects:{},
			unseen:[]
		};
	}

	componentDidMount() {
	}
	componentWillUnmount() {
		// Save state for current session
		Header.profileCache = this.state.profileObjects;
	}
	async componentDidUpdate(prevProps) {
		if ( this.props.profileFetch !== prevProps.profileFetch ) {
			if (this.props.profileFetch.pending) {
			} else if (this.props.profileFetch.rejected) {
				console.error(`${this.props.profileFetch.meta.request.url} ${this.props.profileFetch.reason}`);
					this.setState({profileObjects: {}});
			} else if (this.props.profileFetch.fulfilled) {
					var profileObjects = AppComms.parseApiItems(this.props.profileFetch.value.items, this.state.profileObjects);
					var profile = profileObjects[AppProvider.global.state.cognitoProfile.sub];
					this.setState({profile, profileObjects});
					this.forceUpdate();
			}
		}

		if ( this.props.unseenFetch !== prevProps.unseenFetch ) {
			if (this.props.unseenFetch.pending) {
			} else if (this.props.unseenFetch.rejected) {
				console.error(`${this.props.unseenFetch.meta.request.url} ${this.props.unseenFetch.reason}`);
					this.setState({unseen: {}, unseenObjects: {}});
			} else if (this.props.unseenFetch.fulfilled) {
					var {unseen, unseenObjects} = AppComms.parseApiItems(this.props.unseenFetch.value.items, this.state.unseenObjects);
					this.setState({unseen, unseenObjects});
					this.forceUpdate();
			}
		}
	}
	
	render(location) {
		var rootLocation = location || this.props.location;

		return (
			<div className="Header">
				<Helmet title="You Are Doing Great Header" />
				
				<Container fluid>
					<Row>
						<Col xs="auto">
							<Link to={(HeaderPaths.indexOf('/'+rootLocation.pathname.split('/')[1])>=0)?"/dashboard":"/"}><img src={logo} width="148" height="42" className="App-logo" alt="logo" /></Link>
						</Col>
						<Col>
							<Switch>
								<Route path={HeaderPaths} render={location => (
									<ul>
										<li className={(!rootLocation.pathname.indexOf('/dashboard')?'current':'')}>
											<Link to="/dashboard">Dashboard</Link>
										</li>
										<li className={(!rootLocation.pathname.indexOf('/messages')?'current':'')}>
											<Link to="/messages">Messages</Link>
										</li>
										<li className={(!rootLocation.pathname.indexOf('/timeline')?'current':'')}>
											<Link to="/timeline">Timeline</Link>
										</li>
									</ul>
								)}/>
								<Route render={location => (
									<div/>
								)}/>
							</Switch>
						</Col>
						<Col xs="auto" className="NavToolbar">
							<ButtonToolbar>
								<Switch>
									<Route path={ProfilePaths} render={location => (
										<div>
											<OverlayTrigger trigger={['focus']} placement="bottom" overlay={MessageMenu(this.state.unseen)}>
												<Button hidden={!(this.state.unseen||[]).length} variant="link"><img src="/img/IconMessageUnseen.svg" width="32" height="32" alt="Messages" /></Button>
											</OverlayTrigger>

											<OverlayTrigger trigger={['focus']} placement="bottom" overlay={ProfileMenu(this.state.profile)}>
												<Button variant="link"><Avatar className="ProfileImage" round size="32px" name={((this.state.profile||{}).getName&&this.state.profile.getName()) || (this.state.profile||{}).id} src={((this.state.profile||{}).getImage&&this.state.profile.getImage()) || (0&&'/img/IconPerson.svg') || ''} /></Button>
											</OverlayTrigger>
										</div>
									)}/>
									<Route path="/login" render={location => (
										<Link to="/signup">Sign up</Link>
									)}/>
									<Route render={location => (
										<Link to="/login">Login</Link>
									)}/>
								</Switch>
							</ButtonToolbar>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

export default withRouter(AppComms.connect(props => ({
	profileFetch: {
		url: `profile/me`,
		headers: AppComms.headers
	},
	unseenFetch: {
		url: 'message/unseen',
		headers: AppComms.headers
	}
}))(Header))