// Bootstrap components
//import { Container, Row } from 'react-bootstrap';

//import React, { Component } from 'react';
import React/*, { useState, useEffect }*/ from 'react';
import { Link, withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";
import Avatar from 'react-avatar';

import './SidePanel.css';

// Common components
import AppProvider from '../../AppProvider';
import AppComms from '../../AppComms';
import SidePanelButton from '../buttons/SidePanelButton';

const SidebarPaths = ["/dashboard", "/profile", "/activity", "/timeline", "/companies"];

class SidePanel extends React.Component {
	static profileCache = null;
	
	constructor(props) {
		super(props);

		this.state = {
			profile: null,
			profileObjects: SidePanel.profileCache||{},
		};
	}

	componentDidMount() {
		/*AppProvider.getProfile((profile) => {
			this.setState({profile});
		});*/
	}
	componentWillUnmount() {
		// Save state for current session
		SidePanel.profileCache = this.state.profileObjects;
	}
	async componentDidUpdate(prevProps) {
		if ( this.props.profileFetch !== prevProps.profileFetch ) {
			if (this.props.profileFetch.pending) {
			} else if (this.props.profileFetch.rejected) {
				console.error(`${this.props.profileFetch.meta.request.url} ${this.props.profileFetch.reason}`);
				this.setState({profileObjects: {}});
			} else if (this.props.profileFetch.fulfilled) {
				var profileObjects = AppComms.parseApiItems(this.props.profileFetch.value.items, this.state.profileObjects);
				var profile = profileObjects[AppProvider.global.state.cognitoProfile.sub];
				this.setState({profile, profileObjects});
				this.forceUpdate();
			}
		}
	}

	render() {
		var location = this.props.location;
		
		return (
			<div className={`SidePanelWrapper ${location.pathname.split('/')[1]} ${(SidebarPaths.indexOf('/'+location.pathname.split('/')[1])>=0)?"visible":""}`}>
				<div className={`SidePanel`}>
					<div className={`SideProfile`}>
						<Avatar className="ProfileImage" round size="48px" name={((this.state.profile||{}).getName&&this.state.profile.getName()) || (this.state.profile||{}).id} src={((this.state.profile||{}).getName&&this.state.profile.getImage()) || (0&&'/img/IconPerson.svg') || ''} />
						<div className={`SideProfileText`}>
							<div className="ProfileName">{(this.state.profile||{}).getName && (this.state.profile||{}).getName(false, true)}</div>
							<div className="ProfileType">{(this.state.profile||{}).getType && (this.state.profile||{}).getType()}</div>
							<Link to="/profile" className="ProfileLink">Profile</Link>
						</div>
					</div>

					<h4 className="SidePanelServicesTitle">Services</h4>

					<div className="SidePanelGrid">
						<SidePanelButton link="/activity" icon="IconActivity">Activity</SidePanelButton>
						<SidePanelButton link="/companies" icon="IconCompany">Companies</SidePanelButton>
						<SidePanelButton link="/documents" icon="IconDocument">Documents</SidePanelButton>
						<SidePanelButton link="/messages" icon="IconMessage">Messages</SidePanelButton>
						<SidePanelButton link="/resources" icon="IconResources">Resources</SidePanelButton>
						<SidePanelButton link="/calendar" icon="IconCalendar">Calendar</SidePanelButton>
					</div>
				</div>
			</div>
		);
	}
};

//export default withRouter(SidePanel);
export default withRouter(AppComms.connect(props => ({
	profileFetch: {
			url: `profile/${(((props||{}).match||{}).params||{}).user_id||'me'}`,
			headers: AppComms.headers
	}
}))(SidePanel))