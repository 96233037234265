// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";
import AWS from 'aws-sdk';
import { ConfigProvider } from 'react-avatar';

// Common components
import './App.css';
//import RouteContent from './RouteContent';
import AppContext from './AppContext';
import AppProvider from './AppProvider';
import { Wrapper, Content } from './common/Common';
import Header from './common/Header';
import SidePanel from './components/panels/SidePanel';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Unconfirmed from './pages/Unconfirmed';
import Onboarding from './pages/Onboarding';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Error from './pages/Error';
import Companies from './pages/Companies';
import Activities from './pages/Activities';
import Messages from './pages/Messages';

import $ from 'jquery';

AWS.config.update({
  region: 'us-west-2',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-west-2_02n8IjIvr'
  })
});

class App extends Component {
  constructor(props) {
    super(props);

    this.checkSession();

    setTimeout(function() {
      var $wrapper = $(".Main .Wrapper, .Main, body, html");
      $wrapper.length&&$wrapper.each(w => {
        $wrapper[w].scrollTo&&$wrapper[w].scrollTo(0, 1);
      });
    }, 0);
  }

  render() {
    return (
      <AppProvider>
        <AppContext.Consumer>
          {context => (
            <ConfigProvider colors={['#9E9CFB', '#FFCE20', '#95D555', '#C592EC', '#EC92B3', '#92CCEC', '#FBAD9C', '#FFA620']}>
              <Router context={context}>
                <Route render={location => (
                  <div>
                    <Helmet title="You Are Doing Great Root" />
                    <Header location={location} />

                    <div className="Main">
                      <Container className="MainContainer" fluid>
                        <Row noGutters="true">
                          <Col md="auto" style={{display:'flex'}}>
                            <SidePanel />
                          </Col>

                          <Col>
                            <div className="Wrapper">
                              
                              <Wrapper>
                                <div className="Content">
                                  <section className="route-section">

                                    <Switch>
                                      <Route exact path="/" component={Home} />
                                      <Route path="/topics" component={Topics} />
                                      <Route path="/signup" component={Signup} />
                                      <Route path="/login" component={Login} />
                                      <Route path="/logout" component={Logout} />
                                      <Route path="/unconfirmed" component={Unconfirmed} />
                                      <Route path="/onboarding" component={Onboarding} />
                                      <Route path="/dashboard" component={Dashboard} />
                                      <Route path="/profile/:user_id" component={Profile} />
                                      <Route path="/profile" component={Profile} />
                                      <Route path="/companies" component={Companies} />
                                      <Route path="/activity" component={Activities} />
                                      <Route path="/messages/:message_id" component={Messages} />
                                      <Route path="/messages" component={Messages} />
                                      <Route component={Error} />
                                    </Switch>
                                    
                                  </section>
                                </div>
                              </Wrapper>
                              
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                )}/>
              </Router>
            </ConfigProvider>
          )}
        </AppContext.Consumer>
      </AppProvider>
    );
  }

  checkSession() {
    /*var that = this;
    debugger;
    var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
    var cognitoUser = userPool.getCurrentUser();
    this.context.cognitoUser = cognitoUser;

    cognitoUser&&cognitoUser.getSession(function(err, session) {
        if (err) {
          alert(err);
            return;
        }
        console.log('session validity: ' + session.isValid());
        cognitoUser&&cognitoUser.getUserAttributes(function(err, result) {
          if (err) {
              alert(err);
              return;
          }
          var cognitoProfile = {};
          for (var i = 0; i < result.length; i++) {
              console.log('attribute ' + result[i].getName() + ' has value ' + result[i].getValue());
              cognitoProfile[result[i].getName()] = result[i].getValue();
          }
          that.context.cognitoProfile = cognitoProfile;
        });
    });*/
  }
}



function Topic({ match }) {
  return (
		<div>
      <Helmet title={`You Are Doing Great ${match.params.id}`} />
      <h3>Requested Param: {match.params.id}</h3>
    </div>
  );
}

function Topics({ match }) {
  return (
		<Content>
			<Container>
				<Row noGutters="true">
					<Col>
            <Helmet title="You Are Doing Great Topic2" />
            <h2>Topics</h2>

            <ul>
              <li>
                <Link to={`${match.url}/components`}>Components</Link>
              </li>
              <li>
                <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
              </li>
            </ul>

            <Route path={`${match.path}/:id`} component={Topic} />
            <Route
              exact
              path={match.path}
              render={() => <h3>Please select a topic.</h3>}
            />
          </Col>
        </Row>
      </Container>
    </Content>
  );
}


export default App;