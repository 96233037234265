// Bootstrap components
//import { Row, Col } from 'react-bootstrap';


//import React, { Component } from 'react';
import React from 'react';
import Moment from 'react-moment';
import { Link, withRouter } from "react-router-dom";

import './MessagesFragment.css';

// Common components
import MessageRow from '../components/rows/MessageRow';

class MessagesFragment extends React.Component {
	
    /*constructor(props) {
        super(props);
    }
    componentDidMount(prevProps) {
    }
    componentDidUpdate(prevProps) {
    }*/
    
	render(location, ...props) {
        var messages = this.props.messages;

		return (
            <div className={`MessageList ${this.props.size||''}`}>
                {(() => {
                    // Bucket results by date
                    var messageTimeline = {};
                    messages.sort((a, b) => {
                        return new Date(a.created).getTime() - new Date(b.created).getTime();
                    }).forEach(activity => {
                        var bucketName = activity.created.toLocaleDateString();
                        messageTimeline[bucketName] = messageTimeline[bucketName]||[];
                        messageTimeline[bucketName].push(activity);
                    });

                    return Object.keys(messageTimeline).sort().map(bucketName => {
                        var prevMessageVar = null;
                        return (
                            <div key={bucketName}>
                                <p className="MessageDate light"><Moment format="Do MMMM YYYY">{bucketName}</Moment></p>
                                {(() => {
                                    // Group messages by continuous sender
                                    var groups = [];
                                    messageTimeline[bucketName].forEach(message => {
                                        var prevMessage = prevMessageVar;
                                        if ( message.owner !== (prevMessage||{}).owner ) {
                                            groups.push([]);
                                        }
                                        groups[groups.length-1].push(message);
                                        prevMessageVar = message;
                                    });

                                    return (
                                        groups.map((group, groupIdx) => {
                                            return (
                                                <div key={`${bucketName}_${groupIdx}`} className="MessageGroup">
                                                    {(() => {
                                                        return group.map(message => {
                                                            var prevMessage = prevMessageVar;
                                                            prevMessageVar = message;
                                                            return (
                                                                <div className="MessageOuter" id={`Message_${message.id}`} key={message.id}>
                                                                     {this.props.link ? 
                                                                        <Link to={`/messages/${message.id}`}>
                                                                            <MessageRow message={message} prev={prevMessage} highlight={this.props.match.params.message_id === message.id} />
                                                                        </Link>
                                                                     :
                                                                        <MessageRow message={message} prev={prevMessage} highlight={this.props.match.params.message_id === message.id} />
                                                                     }
                                                                </div>
                                                            );
                                                        })
                                                    })()}
                                                </div>
                                            )
                                        })
                                    )
                                })()}
                            </div>
                        )
                    });
                })()}
            </div>
  	    );
    }
}

export default withRouter(MessagesFragment);