// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

import React from 'react';
import { withRouter } from "react-router-dom";

// Common components
import './SchedulePanel.css';
import SimplePanel from './SimplePanel';

function SchedulePanel({location}) {
  return (
		<SimplePanel title="Schedule">
			<Container fluid>
				<Row>
					<Col>
						blah	
					</Col>
				</Row>
			</Container>
		</SimplePanel>
  );
}

export default withRouter(SchedulePanel);