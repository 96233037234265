// Bootstrap components
import { Container, Row, Col, Form } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Moment from 'react-moment';
import { Link, withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";

import './TaskRow.css';

function TaskRow({ location, ...props }) {
    var task = props.task || {};
    var task_class = task && task.getDisplayClass();
    var task_message = task && task.getDisplayMessage();

    var moment = Moment.globalMoment;
    if ( moment ) {
        moment.relativeTimeThreshold('d');
    }

    var now = new Date();
    var taskType = (new Date(task.due_date || now).getTime() < now.getTime()) ? 'overdue' : 'due';

    // Link to page to complete task
    var taskLink = "#";

    return (
        <Container className={`TaskContainer`}>
            <div className="CheckColumn" style={{float:"left"}}>
                <Form.Check disabled checked={task.complete} hidden={!task.complete && taskType === 'overdue'} className="check" type="checkbox" />
                <img hidden={task.complete || taskType === 'due'} src={`/img/TaskOverdue.svg`} alt="Icon" />
            </div>
            <Row className={`TaskRow ${task_class}`}>
                <Col xs="9" className="TaskColumn">
                    <p><Link hidden={task.complete} className="link" to={taskLink}>{task_message}</Link></p>
                    <p hidden={!task.complete}>{task_message}</p>
                </Col>
                <Col xs="3" className="TimeColumn">
                    <p hidden={!task.due_date}><Moment format="MMM Do">{task.due_date}</Moment></p>
                </Col>
            </Row>
        </Container>
	);
}

export default withRouter(TaskRow);