// Bootstrap components
import { Container, Row, Col, Form, FormLabel, FormControl, Button, Alert } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { Link, withRouter } from "react-router-dom";
//import { AmazonCognitoIdentity } from 'aws-sdk';
//import AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import './Signup.css';

// Common components
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import SimplePanel from '../components/panels/SimplePanel';

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

class Signup extends React.Component {
	
  constructor(props) {
		super(props);
    this.state = {validated: '', error: '', working: false};
    this.form = React.createRef();
    this.alertText = React.createRef();
    this.inputFirstName = React.createRef();
    this.inputLastName = React.createRef();
    this.inputEmail = React.createRef();
    this.inputUsername = React.createRef();
    this.inputPassword1 = React.createRef();
    this.inputPassword2 = React.createRef();
	}
	
	render(location) {
		return (
			<Content>
				<ScrollToTop/>
				<Helmet title="You Are Doing Great Signup" />

        <div hidden={!this.state.working}>
          <lottie-player src="https://assets3.lottiefiles.com/datafiles/5mEmfhAxpgrsoFG/data.json" background="transparent" speed="0.5" style={{"maxWidth":"100%","width":"300px","height":"75px","margin":"15vh auto 0"}} loop autoplay></lottie-player>
        </div>

        <Container hidden={this.state.working}>
					<Row noGutters="true">
						<Col sm="12" md={{"span":"8", "offset":"2"}} lg={{"span":"6", "offset":"3"}}>

							<SimplePanel title="Sign up">
								<Container fluid>
									<Alert show={!!this.state.error.length} variant="danger">
										<Alert.Heading>Oopsie!</Alert.Heading>
										<p>
											{this.state.error}
										</p>
									</Alert>
									
									<Form ref={this.form} noValidate validated={this.state.validated} onSubmit={this.signupActual.bind(this)} style={{'padding':'10px 0 10px'}}>
										<Form.Row>
        									<Form.Group as={Col} md="6" controlId="validationCustom01">
												<FormLabel>First name</FormLabel>
												<FormControl
													required
													ref={this.inputFirstName}
													type="text"
													autoComplete="given-name"
													placeholder="First name"></FormControl>
												<Form.Control.Feedback type="invalid">Enter your first name.</Form.Control.Feedback>
											</Form.Group>
											<Form.Group as={Col} md="6" controlId="validationCustom02">
												<FormLabel>Last name</FormLabel>
												<FormControl
													required
													ref={this.inputLastName}
													type="text"
													autoComplete="family-name"
													placeholder="Last name"></FormControl>
												<Form.Control.Feedback type="invalid">Enter your last name.</Form.Control.Feedback>
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} md="12" controlId="validationCustom03">
												<FormLabel>Your email address</FormLabel>
												<FormControl
													required
													ref={this.inputEmail}
													type="email"
													placeholder="Email address"></FormControl>
												<Form.Control.Feedback type="invalid">Enter a valid email address.</Form.Control.Feedback>
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} md="12" controlId="validationCustom04">
												<FormLabel>Choose a username</FormLabel>
												<Form.Control
													required
													ref={this.inputUsername}
													type="text"
													autoComplete="username"
													placeholder="Username"
													aria-describedby="inputGroupPrepend"></Form.Control>
												<Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} md="12" controlId="validationCustom05">
												<FormLabel>Password <span className="light smaller">(Mix of uppercase, lowercase & numbers)</span></FormLabel>
												<FormControl
													required
													ref={this.inputPassword1}
													type="password"
													autoComplete="new-password"
													placeholder="Password"></FormControl>
												<Form.Control.Feedback type="invalid">Password must contain uppercase, lowercase & numbers.</Form.Control.Feedback>
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} md="12" controlId="validationCustom06">
												<FormLabel>Repeat password</FormLabel>
												<FormControl
													required
													ref={this.inputPassword2}
													type="password"
													autoComplete="new-password"
													placeholder="Password"></FormControl>
												<Form.Control.Feedback type="invalid">Passwords must match.</Form.Control.Feedback>
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} md="12" controlId="validationCustom07" style={{'marginBottom':'0'}}>
												<Button type="submit" variant="primary" size="lg" style={{'margin':'16px 20px 0 0'}}>Sign up</Button>
												<p style={{"display":"inline-block","paddingTop":"16px","verticalAlign":"bottom"}}>Have an account? <Link to="/login" className="primary">Log in here</Link></p>
											</Form.Group>
										</Form.Row>
									</Form>
								</Container>
							</SimplePanel>
						</Col>
					</Row>
				</Container>
			</Content>
		);
	}

	signupActual(event) {
		var that = this;
		var invalid = false;
		const form = event.currentTarget;
		
		event.preventDefault();
		event.stopPropagation();
		
		var firstName = this.inputFirstName.current.value;
		var lastName = this.inputLastName.current.value;
		var email = this.inputEmail.current.value;
		var username = this.inputUsername.current.value;
		var password1 = this.inputPassword1.current.value;
		var password2 = this.inputPassword2.current.value;

		// Basic validation
		if (form.checkValidity() === false) {
			this.setState({validated: true});
			this.setState({error: 'Please correct the errors below'});
			invalid = true;
		}

		// Validate values
		if ( username.length < 4 ) {
			this.setState({error: 'Please provide a username of at least 4 characters'});
			invalid = true;
		}
		if ( password1.length < 8 ) {
			this.setState({error: 'Please provide a password of at least 8 characters'});
			invalid = true;
		}
		if ( password1 !== password2 ) {
			this.inputPassword2.current.value = '';
			this.setState({error: 'Please ensure your passwords match'});
			invalid = true;
		}

		var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
		var attributeList = [
			new AmazonCognitoIdentity.CognitoUserAttribute( { Name : 'given_name', Value : firstName } ),
			new AmazonCognitoIdentity.CognitoUserAttribute( { Name : 'family_name', Value : lastName } ),
			new AmazonCognitoIdentity.CognitoUserAttribute( { Name : 'email', Value : email } ),
		];

		if ( !invalid ) {
			that.setState({working: true});
			userPool.signUp(username, password1, attributeList, null, function(err, result){
				debugger;
					if (err) {
							that.setState({error: err.message});
							that.setState({working: false});
							return;
					}
					//var cognitoUser = result.user;
					//console.log('user name is ' + cognitoUser.getUsername());
					that.setState({working: false});
					that&&that.props.history.push(`/dashboard`)
			});
		}
	}
}

export default withRouter(Signup);