// Import bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//import $ from 'jquery';
//import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import React core components
import React from 'react';
import { render } from 'react-snapshot';	// Replaces the above for prerendered snapshots
import initReactFastclick from 'react-fastclick';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Overrise JSON.stringify with safe (non-circular-throwing) version
//JSON.stringify = require('json-stringify-safe');

/*Object.filter = (obj, predicate) => 
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );*/

const rootEl = document.getElementById('root');
initReactFastclick();

render(
	<App/>,
	rootEl
);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(
      <NextApp />,
      rootEl
    )
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
