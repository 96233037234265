// Bootstrap components
import { Container, Row, Col, Button, Form, InputGroup, Badge } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from "react-router-dom";
import $ from 'jquery';
//import Moment from 'react-moment';

// Models
import Message from '../shared/models/Message.mjs';
import Activity from '../shared/models/Activity.mjs';
import Individual from '../shared/models/Individual.mjs';
import PersonalInformation from '../shared/models/PersonalInformation.mjs';
import ContactInformation from '../shared/models/ContactInformation.mjs';
import DigitalAddress from '../shared/models/DigitalAddress.mjs';
import PhysicalAddress from '../shared/models/PhysicalAddress.mjs';
import Business from '../shared/models/Business.mjs';

// Common components
import AppContext from '../AppContext';
import AppProvider from '../AppProvider';
import AppComms from '../AppComms';
import './Messages.css';
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import SimplePanel from '../components/panels/SimplePanel';
import ThreadRow from '../components/rows/ThreadRow';
//import MessageRow from '../components/rows/MessageRow';
import MessageFragment from '../fragments/MessagesFragment';

class Messages extends React.Component {
	
    constructor(props) {
        super(props);
        this.fetchTimeout = null;
        this.pollInterval = null;
        this.state = {working: true, messageObjects: {}, threads: [], activeThread: null, messages: [], message: ''};
	}

    async componentDidMount(prevProps) {
        clearTimeout( this.fetchTimeout );
        this.fetchTimeout = setTimeout( async() => {
            await this.fetchThreads();
        }, 50);

        // If a message ID is provided in the URL, load its thread here...
        if ( this.props.match.params.message_id ) {
            this.setThreadFromMessageID(this.props.match.params.message_id);
        }
    }
    componentDidUpdate(prevProps) {
        if ( ((this.props||{}).context||{}).profile !== ((prevProps||{}).context||{}).profile ) {
            clearTimeout( this.fetchTimeout );
            this.fetchTimeout = setTimeout( function() {
                this.fetchThreads();
            }.bind(this), 50);
        }
        if ( (((this.props||{}).match||{}).params||{}).message_id !== (((prevProps||{}).match||{}).params||{}).message_id ) {
            debugger;
            if ( this.props.match.params.message_id ) {
                this.setThreadFromMessageID(this.props.match.params.message_id);
            }
        }
    }
    componentWillUnmount() {
        this.closeThread();
    }

    closeThread(event) {
        this.setState({activeThread: null, messages: []});
        clearInterval(this.pollInterval);
    }
    setThread(thread, event) {
        this.closeThread();
        this.setState({activeThread: thread});
        setTimeout( function() {
            // Perform initial fetch (and scroll), then poll/update (without scroll)
            if ( this.fetchMessages(true) ) {
                this.pollInterval = setInterval(this.fetchMessages.bind(this), 10000);
            }
        }.bind(this), 0);
    }
    async setThreadFromMessageID(message_id) {
        var message = await this.getMessage(this.props.match.params.message_id);
        if ( message && message.thread ) {
            this.setThread(message);
        }
    }
    async newThread(event) {
        var userProfile = AppProvider.global.state.profile;
        var newMessage = new Message({owner: userProfile.id});
        var threads = this.state.threads;

        this.closeThread();
        newMessage.recipients = [userProfile.id];
        // TODO: Add other recipients here...
        newMessage.unpack(this.state.messageObjects);
        threads.push(newMessage);
        this.setState({threads, activeThread: newMessage, messages: []});
    }
	messageUpdate(event) {
		this.setState({message: event.target.value});
	}
    async messageSend(event) {
        if ( this.state.message && this.state.activeThread && this.state.activeThread.thread ) {
            var recipients = this.state.activeThread.recipients.map(item => item.id||item);
            var data = await AppComms.post(`message`, { text: this.state.message, thread: this.state.activeThread.thread, recipients });
            if ( data && data.items && data.items["Message"] ) {
                var messageObjects = this.state.messageObjects;
                var messages = this.state.messages;
                data.items["Message"].forEach(item => {
                    var obj = new Message(item);
                    messages.push(obj);
                    messageObjects[obj.id] = obj;
                });

                // Unpack objects (turn IDs into objects)
                Object.keys(messageObjects).forEach(id => {
                    messageObjects[id].unpack(messageObjects);
                });
                
                this.setState({messageObjects, messages, message: ''});

                // Scroll to bottom of panel
                var $wrapper = $(".ConversationPanel");
                $wrapper.length && $wrapper.scrollTop($wrapper[0].scrollHeight - $wrapper.height());
            }
        }
    }
    getRecipientsString() {
        if ( this.state.activeThread ) {
            var names = ((this.state.activeThread||{}).recipients||[]).slice(0,4).map(recipient => (((recipient||{}).getName&&recipient.getName()) || recipient));
            var output = `Conversation with ${names.join(', ')}`;
            if ( ((this.state.activeThread||{}).recipients||[]).length > 4 ) {
                output += `and ${this.state.activeThread.recipients.length - 4} others`;
            } 
            return output;
        }
        return '';
    }
	
	render(location) {
		return (
			<AppContext.Consumer>
				{context => (
					<Content>
						<Helmet title="You Are Doing Great Messages" />
						<ScrollToTop/>
						
						<Container fluid className="MessagesContainer">
							<Row noGutters>
								<Col xs="12" sm="12" md="5" lg="3">
                                    <div className="ThreadList">
                                        <div className="ComposeRow">
                                            <Button onClick={this.newThread.bind(this)} className="primary">New message</Button>
                                        </div>
                                        
                                        <div hidden={!(this.state.threads||[]).length}>
                                            {this.state.threads.map(thread => {
                                                return (
                                                    <div className="ThreadOuter" key={thread.id} onClick={this.setThread.bind(this, thread)}>
                                                        <ThreadRow thread={thread} active={thread.id === (this.state.activeThread||{}).id} />
                                                    </div>
                                                )}
                                            )}
                                        </div>
                
                                        <div hidden={(this.state.threads||[]).length} style={{'textAlign': 'left'}}>
                                            <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_lmaNPc.json" background="transparent" speed="0.5" style={{"width": "100%", "maxWidth": "400px", "transformOrigin": "top left"}} loop autoplay></lottie-player>
                                        </div>

                                    </div>
								</Col>
								<Col xs="12" sm="12" md="7" lg="9">
                                    <div className={`MessagePanel ${this.state.activeThread?'expanded':'collapsed'}`}>
                                        <SimplePanel className="ConversationPanel" xtitle={`Conversation`} color="#F8F8FA" header={
                                            <p className="compact" style={{padding:"6px"}}>
                                                {[...new Set((this.state.activeThread||{}).recipients||[])].map(recipient => (
                                                    <Badge key={recipient.id} variant="primary">{recipient.getName?recipient.getName():'Unknown user'}</Badge>
                                                ))}
                                            </p>
                                        }>
                                            <MessageFragment messages={this.state.messages} />
                                        </SimplePanel>
                                        
                                        <div className="MessagePanelHeader" onClick={this.closeThread.bind(this)}>
                                            <p>Close <span style={{"fontFamily":"dingbats, arial unicode ms, code2000, sans-serif", fontSize:"24px", display:"inline-block", float:"right", marginLeft:"8px"}}>&#x2715;</span></p>
                                        </div>
                                        <Form.Row hidden={!this.state.activeThread} className="InputPanel">
                                            <InputGroup className="InputPanelGroup">
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    value={this.state.message}
                                                    onChange={this.messageUpdate.bind(this)}
                                                    size="lg"></Form.Control>
                                                <InputGroup.Append>
                                                    <Button disabled={!this.state.message.length} onClick={this.messageSend.bind(this)} type="button" variant="primary" size="lg">Send</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Row>
                                    </div>
								</Col>
							</Row>
						</Container>
					</Content>
				)}
			</AppContext.Consumer>
		);
	}

	async fetchThreads() {
		try {
            this.setState({working: true});
            var data = await AppComms.get(`message`, null);
            
			// Create objects
			var messageObjects = {};
            var threads = [];
            var messageTimeline = {};
			Object.keys(data.items).forEach(className => {
				data.items[className].forEach(item => {
					var obj = null;
					switch( className ) {
						case "Message": obj = new Message(item); threads.push(obj); break;
						case "Activity": obj = new Activity(item); break;
                        case 'Individual': obj = new Individual(item); break;
                        case 'PersonalInformation': obj = new PersonalInformation(item); break;
                        case 'ContactInformation': obj = new ContactInformation(item); break;
                        case 'DigitalAddress': obj = new DigitalAddress(item); break;
                        case 'PhysicalAddress': obj = new PhysicalAddress(item); break;
                        case 'Business': obj = new Business(item); break;
						default: break;
					}
					if ( obj ) {
                        obj.init(item);
						messageObjects[item.id] = obj;
					}
				});
            });

            // Unpack objects (turn IDs into objects) and cache in local storage
            Object.keys(messageObjects).forEach(id => {
				messageObjects[id].unpack(messageObjects);
            });

            // Bucket results by date
            threads.sort((a, b) => {
                return new Date(b.created).getTime() - new Date(a.created).getTime();
            }).slice(0, parseInt(this.props.limit||"0")||undefined).forEach(activity => {
                var bucketName = activity.created.toLocaleDateString();
                messageTimeline[bucketName] = messageTimeline[bucketName]||[];
                messageTimeline[bucketName].push(activity);
            });
            
            this.setState({messageObjects, threads});
            this.setState({working: false});
		} catch(e) {
            console.error(e);
            this.setState({messageObjects: {}, threads: []});
            this.setState({working: false});
		}
	}

	async fetchMessages(scrollToNew) {
		try {
            if ( this.state.activeThread ) {
                this.setState({working: true});

                // TODO: Implement `after` param, to perform partial fetched
                var data = await AppComms.get(`message?thread=${this.state.activeThread.thread}&after=0`, null);
                var $wrapper = $(".ConversationPanel .childrow");
                //var scrollTop = $wrapper.length && $wrapper.scrollTop();
                //var updateScroll = scrollToNew ? ( scrollTop === (($wrapper.length && $wrapper[0].scrollHeight) - $wrapper.height()) ) : false;
                
                // Create objects
                var messageObjects = {};
                var messages = [];
                var messageTimeline = {};
                Object.keys(data.items).forEach(className => {
                    data.items[className].forEach(item => {
                        var obj = null;
                        switch( className ) {
                            case "Message": obj = new Message(item); messages.push(obj); break;
                            case "Activity": obj = new Activity(item); break;
                            case 'Individual': obj = new Individual(item); break;
                            case 'PersonalInformation': obj = new PersonalInformation(item); break;
                            case 'ContactInformation': obj = new ContactInformation(item); break;
                            case 'DigitalAddress': obj = new DigitalAddress(item); break;
                            case 'PhysicalAddress': obj = new PhysicalAddress(item); break;
                            case 'Business': obj = new Business(item); break;
                            default: break;
                        }
                        if ( obj ) {
                            obj.init(item);
                            messageObjects[item.id] = obj;
                        }
                    });
                });

                // Unpack objects (turn IDs into objects) and cache in local storage
                Object.keys(messageObjects).forEach(id => {
                    messageObjects[id].unpack(messageObjects);
                });

                // Bucket results by date
                messages.sort((a, b) => {
                    return new Date(a.created).getTime() - new Date(b.created).getTime();
                }).slice(0, parseInt(this.props.limit||"0")||undefined).forEach(activity => {
                    var bucketName = activity.created.toLocaleDateString();
                    messageTimeline[bucketName] = messageTimeline[bucketName]||[];
                    messageTimeline[bucketName].push(activity);
                });
                
                this.setState({messageObjects, messages});
                this.setState({working: false});

                // Scroll to bottom of panel
                if ( scrollToNew && ($wrapper||[]).length ) {
                    $wrapper.scrollTop(0); 
                    //if ( ( $wrapper[0].scrollHeight - $wrapper.height() ) > ( $wrapper.height() / 2 ) ) {
                        //var fullScrollPos = $wrapper[0].scrollHeight - $wrapper.height();
                        var scrollPos = Math.max(0, $(`#Message_${this.state.activeThread.id}`).offset().top - 128);
                        $wrapper.length && $wrapper.scrollTop(scrollPos);//$wrapper[0].scrollHeight - $wrapper.height());
                    //}
                }
                return true;
            } else {
                this.setState({messageObjects: {}, messages: []});
                this.setState({working: false});
            }
		} catch(e) {
            console.error(e);
            this.setState({messageObjects: {}, messages: []});
            this.setState({working: false});
        }
        return false;
	}

	async getMessage(message_id) {
		try {
            this.setState({working: true});

            // TODO: Implement `after` param, to perform partial fetched
            var data = await AppComms.get(`message/${message_id}`, null);
            
            // Create objects
            var message = null;
            var messageObjects = this.state.messageObjects||{};
            Object.keys(data.items).forEach(className => {
                data.items[className].forEach(item => {
                    var obj = null;
                    switch( className ) {
                        case "Message": obj = new Message(item); message = obj; break;
                        case "Activity": obj = new Activity(item); break;
                        case 'Individual': obj = new Individual(item); break;
                        case 'PersonalInformation': obj = new PersonalInformation(item); break;
                        case 'ContactInformation': obj = new ContactInformation(item); break;
                        case 'DigitalAddress': obj = new DigitalAddress(item); break;
                        case 'PhysicalAddress': obj = new PhysicalAddress(item); break;
                        case 'Business': obj = new Business(item); break;
                        default: break;
                    }
                    if ( obj ) {
                        obj.init(item);
                        messageObjects[item.id] = obj;
                    }
                });
            });

            // Unpack objects (turn IDs into objects) and cache in local storage
            Object.keys(messageObjects).forEach(id => {
                messageObjects[id].unpack(messageObjects);
            });
            
            this.setState({messageObjects});
            this.setState({working: false});

            return message;
		} catch(e) {
            console.error(e);
            this.setState({working: false});
        }
        return null;
	}
};

export default withRouter(Messages);
/*export default withRouter(AppComms.connect(props => ({
    threadsFetch: {
        url: `message`,
        headers: AppComms.headers
    }
}))(Messages))*/