// Bootstrap components
//import { Col } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import { Link, withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";

import './SidePanelButton.css';

function SidePanelButton({ location, ...props }) {
	return (
			<Link to={props.link}>
				<button className={`SidePanelButton`}>
					<img src={"/img/"+props.icon+".svg"} width="30" height="30" className={props.icon} alt={props.icon} />
					<span>{props.children}</span>
				</button>
			</Link>
	);
}

export default withRouter(SidePanelButton);