// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";
import Parser from 'html-react-parser';

import './ActivityRow.css';

function ActivityRow({ location, ...props }) {
    var activity = props.activity || {};
    var activity_class = activity && activity.getDisplayClass();
    var activity_message = activity && activity.getDisplayMessage();

    var moment = Moment.globalMoment;
    if ( moment ) {
        moment.relativeTimeThreshold('d');
    }

    return (
        <Container className={`ActivityContainer ${activity_class}`}>
            <div className="DotColumn" style={{float:"left"}}>
                <div className="dot"></div>
                <img src={`/img/${activity_class}.svg`} alt="Icon" />
            </div>
            <Row className="ActivityRow">
                <Col xs="9" className="ActivityColumn">
                    <p>{Parser(activity_message)}</p>
                </Col>
                <Col xs="3" className="TimeColumn">
                    <p><Moment format="h:mm a">{activity.created}</Moment></p>
                </Col>
            </Row>
        </Container>
	);
}

export default withRouter(ActivityRow);