// Bootstrap components
//import { Row, Col } from 'react-bootstrap';


//import React, { Component } from 'react';
import React from 'react';
//import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
import getUuid from 'uuid-by-string';

import './TasksFragment.css';

// Common components
import AppComms from '../AppComms';
import AppProvider from '../AppProvider';
import SimplePanel from '../components/panels/SimplePanel';
import TaskRow from '../components/rows/TaskRow';

class TasksFragment extends React.Component {
    static taskCache = null;
    
    constructor(props) {
        super(props);

        // Restore state for this session
        this.state = {
            taskObjects: TasksFragment.taskCache||{}
        };
    }
    
    componentWillUnmount() {
        // Save state for current session
        TasksFragment.taskCache = this.state.taskObjects;
    }
    componentDidMount() {
    }
    async componentDidUpdate(prevProps) {
        if ( ( this.props.taskStatic !== prevProps.taskStatic ) || ( this.props.taskFetch !== prevProps.taskFetch ) ) {
            if ( this.props.taskStatic.pending || this.props.taskFetch.pending ) {
            } else if ( this.props.taskStatic.rejected || this.props.taskFetch.rejected ) {
                console.error(`${this.props.taskFetch.meta.request.url} ${this.props.taskFetch.reason}`);
                this.setState({taskObjects: {}});
            } else if ( this.props.taskStatic.fulfilled && this.props.taskFetch.fulfilled ) {
                (this.props.taskFetch.value.items||{})["Task"] = (this.props.taskStatic.value.items||{})["Task"].concat((this.props.taskFetch.value.items||{})["Task"]);
                var taskObjects = AppComms.parseApiItems(this.props.taskFetch.value.items, this.state.taskObjects);
                this.setState({taskObjects});
                this.forceUpdate();
            }
        }
    }
    taskCount(taskObjects) {
        return Object.values(taskObjects||{}).filter(obj => obj.tableName() === "Task").length;
    }
    taskSections(taskObjects) {
        var taskArray = Object.values(taskObjects||{}).filter(obj => obj.tableName() === "Task");
        return this.searchBucket(taskArray);
    }
    
    render(location) {
        return (
            <SimplePanel title={this.props.title}>

                <div hidden={!this.taskCount(this.state.taskObjects)}>
                    {Object.keys(this.taskSections(this.state.taskObjects)).sort((a, b) => {
                        if ( a < b ) return -1;
                        if ( b < a ) return 1;
                        return 0;
                    }).map(bucket => {
                        return (
                            <div key={bucket} className="taskBucket">
                                <h4 className="taskBucketTitle primary" style={{margin:"12px 0 0"}}>
                                    {bucket === '1_overdue' ? "Overdue tasks" : ( bucket === '2_upcoming' ? "Upcoming tasks" : "Completed tasks")}
                                </h4>

                                {(this.taskSections(this.state.taskObjects))[bucket].map(item => 
                                    <TaskRow key={item.id} task={item}/>
                                )}
                            </div>
                        )}
                    )}
                </div>
                
                <div hidden={this.taskCount(this.state.taskObjects)} style={{'textAlign': 'left'}}>
                    <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_lmaNPc.json" background="transparent" speed="0.5" style={{"width": "50%", "maxWidth": "320px", "transformOrigin": "top left", "transform": "scaleX(2)"}} loop autoplay></lottie-player>
                </div>

                {this.props.children}

            </SimplePanel>
        );
    }
    searchBucket(tasks) {
        // Bucket results by date
        var searchTimeline = {};
        tasks.sort((a, b) => {
            if ( !a.complete && b.complete ) return -1;
            return new Date(b.due_date||b.created).getTime() - new Date(a.due_date||a.created).getTime();
        }).slice(0, parseInt(this.props.limit||"0")||undefined).forEach(task => {
            var now = new Date();
            var bucketName = '2_upcoming';
            if ( task.complete ) bucketName = '3_complete';
            else if ( (new Date(task.due_date || now).getTime() < now.getTime()) ) bucketName = '1_overdue';
            searchTimeline[bucketName] = searchTimeline[bucketName]||[];
            searchTimeline[bucketName].push(task);
        });
        return searchTimeline;
    }

    searchActual(event) {
        event.preventDefault();
        event.stopPropagation();

        this.fetchTasks();
    }
}

export default withRouter(AppComms.connect(props => ({
    taskStatic: {
        value: {
            items: {
                "Task": [
                    {
                        id: getUuid('setupprofile'),
                        owner: AppProvider.global.state.profile.id,
                        type: 'setupprofile',
                        created: AppProvider.global.state.profile.created,
                        due_date: null,
                        complete: AppProvider.global.state.profile.isPersonalComplete && AppProvider.global.state.profile.isPersonalComplete()
                    },
                    {
                        id: getUuid('setupfamily'),
                        owner: AppProvider.global.state.profile.id,
                        type: 'setupfamily',
                        due_date: AppProvider.global.state.profile.created
                    },
                    {
                        id: getUuid('setupcycle'),
                        owner: AppProvider.global.state.profile.id,
                        type: 'setupcycle',
                        due_date: AppProvider.global.state.profile.created 
                    }
                ]
            }
        }
    },
    taskFetch: {
        url: `task`,
        headers: AppComms.headers
    }
}))(TasksFragment))