// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
import AppProvider from '../../AppProvider';
import AppComms from '../../AppComms';
//import { TransitionGroup, CSSTransition } from "react-transition-group";
import Avatar from 'react-avatar';

import './MessageRow.css';

function MessageRow({ location, ...props }) {
    var userProfile = AppProvider.global.state.profile;
    var prev = props.prev || {};
    var message = props.message || {};
    var message_message = message.text;

    var moment = Moment.globalMoment;
    if ( moment ) {
        moment.relativeTimeThreshold('d');
    }

    var messageClass = (((message.owner||{}).id||message.owner) === (userProfile||{}).id) ? 'owner' : 'recipient';
    var joined = ( props.prev && (message.owner === prev.owner) ) ? true : false;
    var unseen = (message.seen_by||[]).indexOf(userProfile.id) && ((message.owner||{}).id !== userProfile.id);

    if ( unseen ) {
        // Mark as seen after <x> seconds
        setTimeout( async function() {
            var data = await AppComms.get(`message/${message.id}/seen`, null);
            if ( (data && ((data.items||{}).Message||[]).length) ) {
                props.message = data.items.Message[0];
            }
        }, 3000);
    }

    return (
        <Container fluid className={`MessageContainer ${messageClass} ${unseen?'unseen':'seen'} ${joined?'joined':'first'} ${props.highlight?'highlight':''}`}>
            <div className="ImageColumn" style={{float:"left"}}>
                <Avatar className="ProfileImage" round size="32px" name={((message.owner||{}).getName&&message.owner.getName()) || message.owner} src={((message.owner||{}).getName&&message.owner.getImage()) || (0&&'/img/IconPerson.svg') || ''} />
            </div>
            <Row className={`MessageRow`}>
                <div className={`HeaderRow`}>
                    <p className="NameValue compact small">{((message.owner||{}).getName&&message.owner.getName()) || 'Unknown user'}</p>
                    <p className="TimeValue compact small"><Moment format="h:mma">{message.created}</Moment></p>
                </div>
                <Col xs="auto" className="MessageColumn" title={message.created}>
                    <p className="compact medium">{message_message}</p>
                </Col>
            </Row>
        </Container>
	);
}

export default withRouter(MessageRow);