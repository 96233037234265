// Bootstrap components
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import Avatar from 'react-avatar';
import { Link, withRouter } from "react-router-dom";
import getUuid from 'uuid-by-string';
import $ from 'jquery';

import './Profile.css';

// Models
import Message from '../shared/models/Message.mjs';
import Activity from '../shared/models/Activity.mjs';
import Individual from '../shared/models/Individual.mjs';
import PersonalInformation from '../shared/models/PersonalInformation.mjs';
import ContactInformation from '../shared/models/ContactInformation.mjs';
import DigitalAddress from '../shared/models/DigitalAddress.mjs';
import PhysicalAddress from '../shared/models/PhysicalAddress.mjs';
import Business from '../shared/models/Business.mjs';

// Common components
import AppContext from '../AppContext';
import AppProvider from '../AppProvider';
import AppComms from '../AppComms';
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import SimplePanel from '../components/panels/SimplePanel';
import ActivityFragment from '../fragments/ActivityFragment';
import MessageFragment from '../fragments/MessagesFragment';

class Profile extends React.Component {
	static profileCache = null;
	
	constructor(props, context) {
		super(props);

		this.fetchMessageTimeout = null;

		//var userObjects = AppProvider.global.state.userObjects||{};
		/*var profile = AppProvider.global.state.profile;
		if ( this.props.match.params.user_id ) {
			profile = AppProvider.global.state.userObjects[this.props.match.params.user_id];
		}*/

		this.state = {
			//profile,
			thread_id: null,
			//userObjects: userObjects,
			profileObjects: Profile.profileCache||{},
			messages: [],
			message: ''
		};
	}
	/*setStateSafe(newState) {
		if (this._ismounted) { // This is bad.
			this.setState(newState);
		}
	}*/

	async componentDidMount() {
		//this._ismounted = true;
		/*if ( this.props.match.params.user_id ) {
			this.setStateSafe({profile: AppProvider.global.state.userObjects[this.props.match.params.user_id]});
			await this.fetchProfile(this.props.match.params.user_id);
		} else {
			AppProvider.getProfile(async(profile, userObjects) => {
				this.setStateSafe({profile, userObjects});
				await this.fetchProfile(profile.id);
			});
		}
		this.setupPrivateThread();*/
	}
	componentWillUnmount() {
		//this._ismounted = false;
		clearTimeout(this.fetchMessageTimeout);
		clearInterval(this.pollInterval);

		// Save state for current session
		Profile.profileCache = this.state.profileObjects;
	}
	async componentDidUpdate(prevProps) {

		if ( this.props.profileFetch !== prevProps.profileFetch ) {
			if (this.props.profileFetch.pending) {
			} else if (this.props.profileFetch.rejected) {
				console.error(`${this.props.profileFetch.meta.request.url} ${this.props.profileFetch.reason}`);
				this.setState({profileObjects: {}});
			} else if (this.props.profileFetch.fulfilled) {
				var profileObjects = AppComms.parseApiItems(this.props.profileFetch.value.items, this.state.profileObjects);
				var profile = profileObjects[AppProvider.global.state.cognitoProfile.sub];
				this.setState({profile, profileObjects});
				this.forceUpdate();
			}
		}
	}

	setupPrivateThread() {
		AppProvider.getProfile((userProfile) => {
			(async() => {
				if ( userProfile ) {
					this.setStateSafe({thread_id: getUuid([userProfile.id, (this.profile()||{}).id].sort().join(','))});
					clearTimeout( this.fetchMessageTimeout );
					this.fetchMessageTimeout = setTimeout( async function() {
						// Perform initial fetch (and scroll), then poll/update (without scroll)
						if ( await this.fetchMessages(true) ) {
							clearInterval(this.pollInterval);
							this.pollInterval = setInterval(await this.fetchMessages.bind(this), 10000);
						} else {
							clearInterval(this.pollInterval);
							this.pollInterval = null;
						}
					}.bind(this), 800);
				}
			})();
		});
	}
	privateMessageUpdate(event) {
		this.setStateSafe({message: event.target.value});
	}
	async privateMessageSend(event) {
		AppProvider.getProfile((userProfile) => {
			(async() => {
				if ( this.state.message && this.state.thread_id ) {
					var recipients = [userProfile.id, this.profile().id];
					var data = await AppComms.post(`message`, { text: this.state.message, thread: this.state.thread_id, recipients });
					if ( data && data.items && data.items["Message"] ) {
						var userObjects = this.state.userObjects;
						var messages = this.state.messages;
						data.items["Message"].forEach(item => {
							var obj = new Message(item);
							messages.push(obj);
							userObjects[obj.id] = obj;
						});

            // Unpack objects (turn IDs into objects) and cache in local storage
            Object.keys(userObjects).forEach(id => {
							userObjects[id].unpack(userObjects);
            });
						
						this.setStateSafe({userObjects, messages, message: ''});

						// Scroll to bottom of panel
						var $wrapper = $(".ConversationPanel");
						$wrapper.length && $wrapper.scrollTop($wrapper[0].scrollHeight - $wrapper.height());
					}
				}
			})();
		});
	}
	updateValue(scope, key, event) {
		scope[key] = event.target.value;
		this.setStateSafe({profile: this.state.profile});
	}

	
	profile() {
		return this.state.profile;
	}
	personal() {
		return (this.profile()||{}).getPersonal ? this.profile().getPersonal() : {};
	}
	contact() {
		return (this.personal()||{}).getContact ? this.personal().getContact() : {};
	}
	render(location) {
		return (
			<AppContext.Consumer>
				{context => (
					<Content>
						<Container>
							<Row noGutters>
								<Col>
									<ScrollToTop/>
									<Helmet title="You Are Doing Great Profile" />

									{this.profile() ? 
										<div>
											<div className="ProfileHeader">
												<Avatar className="ProfileImage" round size="160px" name={(this.personal().getName && this.personal().getName()) || this.profile.id} src={(this.personal().getImage && this.personal().getImage()) || (0&&'/img/IconPerson.svg') || ''} />
												<h2 className="ProfileName compact">{this.personal().getName && this.personal().getName(false, true)}</h2>
												<p className="ProfileType">{(this.profile()||{}).getType && this.profile().getType()}</p>
											</div>

											<Container className="ProfileContainer">
												<Form noValidate>
													<Row>
														<Col xs="12" lg="6">
															<SimplePanel title="Personal information">
																<Form.Row>
																	<Form.Group as={Col} xs="4" md="4" lg="4">
																		<Form.Label>Title</Form.Label>
																		<Form.Control as="select" autoComplete="honorific-prefix" value={this.personal().title} onChange={this.updateValue.bind(this, this.personal(), 'title')}>
																			<option>Choose...</option>
																			{PersonalInformation.titles.map(title =>
																				<option key={title.key} value={title.key}>{title.value}</option>
																			)}
																		</Form.Control>
																	</Form.Group>
																	<Form.Group as={Col} xs="8" md="8" lg="8">
																		<Form.Label>First name</Form.Label>
																		<Form.Control required type="text" autoComplete="given-name" value={this.personal().first_name} onChange={this.updateValue.bind(this, this.personal(), 'first_name')}></Form.Control>
																	</Form.Group>
																	<Form.Group as={Col} xs="8" md="8" lg="8">
																		<Form.Label>Last name</Form.Label>
																		<Form.Control required type="text" autoComplete="family-name" value={this.personal().last_name} onChange={this.updateValue.bind(this, this.personal(), 'last_name')}></Form.Control>
																	</Form.Group>
																	<Form.Group as={Col} xs="4" md="4" lg="4">
																		<Form.Label>Suffix</Form.Label>
																		<Form.Control required type="text" autoComplete="honorific-suffix" value={this.personal().suffix} onChange={this.updateValue.bind(this, this.personal(), 'suffix')}></Form.Control>
																	</Form.Group>
																</Form.Row>
																<Form.Row>
																	<Form.Group as={Col} lg="12" xl="6">
																		<Form.Label>Gender</Form.Label>
																		<Form.Control as="select" autoComplete="sex" value={this.personal().gender} onChange={this.updateValue.bind(this, this.personal(), 'gender')}>
																			<option value="">Choose...</option>
																			{PersonalInformation.genders.map(gender =>
																				<option key={gender.key} value={gender.key}>{gender.value}</option>
																			)}
																			<option value="<Custom>">Prefer to specify</option>
																		</Form.Control>
																	</Form.Group>
																	<Form.Group hidden={!this.personal().gender || PersonalInformation.knownGender(this.personal().gender)} as={Col} lg="12" xl="6">
																		<Form.Label>Chosen gender</Form.Label>
																		<Form.Control required type="text" placeholder="Gender" value={(this.personal().gender||'').replace('<Custom>','')} onChange={this.updateValue.bind(this, this.personal(), 'gender')}></Form.Control>
																	</Form.Group>
																</Form.Row>
																<Form.Row>
																	<Form.Group as={Col} sm="5" lg="6" xl="5">
																		<Form.Label>Sex</Form.Label>
																		<Form.Control as="select" autoComplete="sex" value={this.personal().sex} onChange={this.updateValue.bind(this, this.personal(), 'sex')}>
																			<option>Choose...</option>
																			{PersonalInformation.sexes.map(sex =>
																				<option key={sex.key} value={sex.key}>{sex.value}</option>
																			)}
																		</Form.Control>
																	</Form.Group>
																</Form.Row>
																<Form.Row style={{textAlign:'right'}}>
																	<Button type="submit" variant="primary" size="md" style={{'margin':'8px 0 8px auto'}}>Save changes</Button>
																</Form.Row>
															</SimplePanel>

															<SimplePanel title="Contact information">
																<div>
																	{(this.contact().digital_addresses||[]).map(item => {
																		return (
																			<div key={item.id||item}>
																				<div>{item.type} ({item.name||item})</div>
																				<div>{item.value}</div>
																			</div>
																		)
																	})}

																	{(this.contact().physical_addresses||[]).map(item => {
																		return (
																			<div key={item.id}>
																				<div>{item.type} ({item.name})</div>
																				<div>{item.value}</div>
																			</div>
																		)
																	})}
																</div>
															</SimplePanel>
														</Col>
														<Col xs="12" lg="6">
															<SimplePanel title="Private message" className="PrivateMessagePanel" footer={
																<InputGroup className="InputPanelGroup">
																	<Form.Control
																		required
																		type="text"
																		value={this.state.message}
																		onChange={this.privateMessageUpdate.bind(this)}
																		size="md"></Form.Control>
																	<InputGroup.Append>
																		<Button disabled={!this.state.message.length} onClick={this.privateMessageSend.bind(this)} type="button" variant="primary" size="md">Send</Button>
																	</InputGroup.Append>
																</InputGroup>
															}>
																<MessageFragment size="md" messages={this.state.messages} />
															</SimplePanel>

															<ActivityFragment context={context} title="Recent activity" limit="10">
																<p className="compact right"><Link to="/activity" className="primary">See all activity</Link></p>
															</ActivityFragment>
														</Col>
													</Row>
												</Form>
											</Container>
										</div>
									:
										<lottie-player src="https://assets3.lottiefiles.com/datafiles/5mEmfhAxpgrsoFG/data.json" background="transparent" speed="0.5" style={{"maxWidth":"100%","width":"300px","height":"75px","margin":"25vh auto 0"}} loop autoplay></lottie-player>
									}
								</Col>
							</Row>
						</Container>
					</Content>
				)}
			</AppContext.Consumer>
		)
	}

	async fetchMessages(scrollToNew) {
		try {
			if ( this.state.thread_id ) {
				// TODO: Implement `after` param, to perform partial fetched
				var data = await AppComms.get(`message?thread=${this.state.thread_id}&after=0`, null);

				if ( data && data.items ) {
					var $wrapper = $(".PrivateMessagePanel .childrow");
					//var scrollTop = $wrapper.length && $wrapper.scrollTop();
					//var updateScroll = scrollToNew ? ( scrollTop === (($wrapper.length && $wrapper[0].scrollHeight) - $wrapper.height()) ) : false;
					
					// Create objects
					var userObjects = this.state.userObjects||{};
					var messages = [];
					var messageTimeline = {};
					Object.keys(data.items).forEach(className => {
						data.items[className].forEach(item => {
							var obj = null;
							switch( className ) {
								case "Message": obj = new Message(item); messages.push(obj); break;
								case "Activity": obj = new Activity(item); break;
								case 'Individual': obj = new Individual(item); break;
								case 'PersonalInformation': obj = new PersonalInformation(item); break;
								case 'ContactInformation': obj = new ContactInformation(item); break;
								case 'DigitalAddress': obj = new DigitalAddress(item); break;
								case 'PhysicalAddress': obj = new PhysicalAddress(item); break;
								case 'Business': obj = new Business(item); break;
								default: break;
							}
							if ( obj ) {
								obj.init(item);
								userObjects[item.id] = obj;
							}
						});
					});

					// Unpack objects (turn IDs into objects) and cache in local storage
					Object.keys(userObjects).forEach(id => {
						userObjects[id].unpack(userObjects);
					});

					// Bucket results by date
					messages.sort((a, b) => {
						return new Date(a.created).getTime() - new Date(b.created).getTime();
					}).slice(0, parseInt(this.props.limit||"0")||undefined).forEach(activity => {
						var bucketName = activity.created.toLocaleDateString();
						messageTimeline[bucketName] = messageTimeline[bucketName]||[];
						messageTimeline[bucketName].push(activity);
					});
					
					this.setStateSafe({userObjects, messages});
					
					// Scroll to bottom of panel
					if ( scrollToNew && ($wrapper||[]).length ) {
						$wrapper.scrollTop(0); 
						//if ( ( $wrapper[0].scrollHeight - $wrapper.height() ) > ( $wrapper.height() / 2 ) ) {
							var scrollPos = $wrapper[0].scrollHeight - $wrapper.height();
							$wrapper.length && $wrapper.scrollTop(scrollPos);
						//}
					}
					return true;
				}
			}
		} catch(e) {
			console.error(e);
			this.setStateSafe({userObjects: {}, messages: []});
		}
		return false;
	}
}

export default withRouter(AppComms.connect(props => ({
	profileFetch: {
			url: `profile/${(((props||{}).match||{}).params||{}).user_id||'me'}`,
			headers: AppComms.headers
	}
}))(Profile))