//import { PureComponent } from 'react';

// Import React core components
import React, { Component } from 'react';

import $ from 'jquery';

/*class ScrollToTop extends Component {
	SideEffect() {
		window.scrollTo(0, 0);
	}
	render() {
		return (
			<div/>
		);
	}
};*/

/*const ScrollToTop = ({ children, location }) => {
  Component.SideEffect(() => {
    window.scrollTo(0, 0);
  }, [(location||{}).pathname]);

  return children || null;
};*/

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
	UNSAFE_componentWillMount() {
		//window.scrollTo(0, 0);
		//debugger;
		var $wrapper = $(".Main .Wrapper");
		$wrapper.length&&$wrapper[0].scrollTo(0, 0);
	}
	render() {
    return null;
  }
}

export default ScrollToTop;