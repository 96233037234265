// Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, Link, withRouter } from "react-router-dom";

import './Error.css';

// Common components
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';

function Error({location}) {
  return (
		<Content>
      <ScrollToTop/>
      <Helmet title="You Are Doing Great Error" />

			<Container>
				<Row noGutters="true">
					<Col>
            <div style={{'textAlign': 'center', 'paddingTop': '5vh'}}>
              <lottie-player hidden src="https://assets7.lottiefiles.com/temp/lf20_dzWAyu.json" background="transparent" speed="2" style={{"width": "300px", "height": "200px", "margin": "0 auto"}} autoplay></lottie-player>
              <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_Dbty6K.json" background="transparent" speed="1.5" style={{"width": "300px", "height": "300px", "margin": "0 auto"}} loop autoplay></lottie-player>

              <h2 style={{'color': '#95D555', 'paddingBottom': '20px'}}>Um... this is awkward...</h2>
              <p>The page you tried to visit could not be found, or we messed something up.</p>

              <Switch>
                <Route path={["/dashboard", "/profile", "/messages", "/timeline"]} render={location => (
                  <p>Not to worry... you can return to your <Link to="/dashboard" className="link">dashboard</Link>,<br/>or visit our <Link to="/" className="link">our homepage</Link> instead.</p>
                )}/>
                <Route render={location => (
                  <p>Not to worry... you can head over to <Link to="/" className="link">our homepage</Link>,<br/>or if you already have an account <Link to="/login" className="link">login</Link> instead.</p>
                )}/>
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>
    </Content>
  );
}

export default withRouter(Error);