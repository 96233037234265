// Bootstrap components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
//import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";

// Common components
import './Home.css';
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';

function Home({location}) {
  return (
		<Content>
      <Helmet title="You Are Doing Great Home" />
      <ScrollToTop/>

			<Container>
				<Row noGutters="true">
					<Col>
            <h2>Home</h2>
            <Button>button</Button>
            <ul>
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/messages">Messages</Link></li>
              <li><Link to="/timeline">Timeline</Link></li>
              <li><Link to="/profile">Profile</Link></li>
            </ul>

            <div style={{ height: "2000px" }}>I will overflow</div>
          </Col>
        </Row>
      </Container>
    </Content>
  );
}

export default withRouter(Home);