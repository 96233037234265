import React, { Component } from 'react';

import AppContext from './AppContext';
import AppComms from './AppComms';

// Models
import Individual from './shared/models/Individual.mjs';

//import AWS from 'aws-sdk';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export function marshallCognitoProfile(cognitoAttributes) {
	var cognitoProfile = {};
	for (var i = 0; i < cognitoAttributes.length; i++) {
		//console.log('attribute ' + result[i].getName() + ' has value ' + result[i].getValue());
		cognitoProfile[cognitoAttributes[i].getName()] = cognitoAttributes[i].getValue();
	}
	return cognitoProfile;
}

class AppProvider extends Component {
	static cachedCognitoProfile = window.localStorage?JSON.parse(window.localStorage.getItem('cognitoProfile')):null;
	static cachedProfile = window.localStorage?JSON.parse(window.localStorage.getItem('userProfile')):null;
	static global = {
		state: {
			loggedIn: AppProvider.cachedCognitoProfile?true:false,
			cognitoUser: null,
			cognitoProfile: AppProvider.cachedCognitoProfile,
			profile: AppProvider.cachedProfile?(new Individual(AppProvider.cachedProfile)):null,
			profileObjects: null
		}
	};
	static getProfile(done) {
		var profileTimeout = null;
		
		if ( AppProvider.global.state.cognitoProfile && !AppProvider.global.state.profile ) {
			// Cognito profile exists, but no user profile... kick off the fetch
			//await AppProvider.cacheUserObjects();
			//await AppProvider.fetchUserData();
		} else if ( AppProvider.global.state.cognitoProfile && AppProvider.global.state.profile && (typeof AppProvider.global.state.profile !== "undefined") && (typeof AppProvider.global.state.profile !== "undefined") ) {
			//await AppProvider.cacheUserObjects();
			//await AppProvider.fetchUserData();
			clearInterval(profileTimeout);
			done(AppProvider.global.state.profile.unpack(AppProvider.global.state.profileObjects), AppProvider.global.state.profileObjects);
		} else {
			profileTimeout = setInterval(async function() {
				if ( AppProvider.global.state.cognitoProfile && AppProvider.global.state.profile && (typeof AppProvider.global.state.profile !== "undefined") && (typeof AppProvider.global.state.profileObjects !== "undefined")) {
					//await AppProvider.cacheUserObjects();
					clearInterval(profileTimeout);
					done(AppProvider.global.state.profile.unpack(AppProvider.global.state.profileObjects), AppProvider.global.state.profileObjects);
				}
			}, 5);
		}
		return profileTimeout;
	}


	state = AppProvider.global.state;

	componentDidMount() {
		this.restoreProfile();
	}
	async componentDidUpdate(prevProps) {
		if ( this.props.profileFetch !== prevProps.profileFetch ) {
			if (this.props.profileFetch.pending) {
			} else if (this.props.profileFetch.rejected) {
				console.error(`${this.props.profileFetch.meta.request.url} ${this.props.profileFetch.reason}`);
				this.setState({profileObjects: {}});
			} else if (this.props.profileFetch.fulfilled) {
				var profileObjects = AppComms.parseApiItems(this.props.profileFetch.value.items, this.state.profileObjects);
				var profile = profileObjects[AppProvider.global.state.cognitoProfile.sub];
				this.setState({profile, profileObjects});
				this.forceUpdate();
			}
		}
	}

	restoreProfile(done) {
		this.restoreSession((success, cognitoProfile) => {
			//debugger;
			if ( success ) {
				AppProvider.getProfile((profile, profileObjects) => {
					//debugger;
					this.setState({ profileObjects, profile });
					if ( done ) done(success);
				});
			} else {
				if ( done ) done(success);
			}
		});
	}


	render() {
		return (
			<AppContext.Provider
				value={{
					// Properties
					cognitoUser: this.state.cognitoUser,
					cognitoProfile: this.state.cognitoProfile,
					profile: this.state.profile,
					profileObjects: this.state.profileObjects,

					// Methods
					updateCognitoProfile: this.updateCognitoProfile.bind(this),
					restoreSession: this.restoreSession.bind(this),
					logoutSession: this.logoutSession.bind(this),
					restoreProfile: this.restoreProfile.bind(this),
				}}>
				{this.props.children}
			</AppContext.Provider>
		);
	}

	updateCognitoProfile(cognitoAttributes) {
		var cognitoProfile = marshallCognitoProfile(cognitoAttributes);
		this.setState({cognitoProfile});
		return cognitoProfile;
	}

	restoreSession(done) {
		var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
		var cognitoUser = userPool.getCurrentUser();
		this.setState({cognitoUser});
		this.setState({cognitoProfile: null});

		if ( cognitoUser ) {
			// Fetch prior profile
			//this.setState({cognitoProfile: window.localStorage?JSON.parse(window.localStorage.getItem('cognitoProfile')):null});

			cognitoUser.getSession(function(err, session) {
				if (err) {
					if ( err.code === "UserNotConfirmedException" ) {
						this.logoutSession();
					} else {
						this.logoutSession();
					}
					if ( done ) done(false);
					return;
				}

				//console.log('session validity: ' + session.isValid());
				cognitoUser&&cognitoUser.getUserAttributes(function(err, result) {
					if (err) {
						this.logoutSession();
						if ( done ) done(false);
						return;
					}

					// Store cognito profile values
					var cognitoProfile = this.updateCognitoProfile(result);
					this.setState({loggedIn: true, cognitoProfile});
					AppProvider.global.state.cognitoProfile = cognitoProfile;
					window.localStorage.setItem('cognitoProfile', JSON.stringify(cognitoProfile));
					//this.setState({cognitoProfile: window.localStorage?JSON.parse(window.localStorage.getItem('cognitoProfile')):null});
					if ( done ) done(true, cognitoProfile);

				}.bind(this));
			}.bind(this));
		} else {
			if ( done ) done(false);
		}
	}
	logoutSession() {
		var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
		var cognitoUser = userPool.getCurrentUser();
	
		if (cognitoUser != null) {
			debugger;
			cognitoUser.signOut();
			this.setState({cognitoUser: null});
			this.setState({cognitoProfile: null});
			window.localStorage.removeItem('cognitoProfile');
			window.localStorage.removeItem('cognitoUser');
		}
	}
}

//export default AppProvider;
export default AppComms.connect(props => ({
	profileFetch: {
			url: `profile/me`,
			headers: AppComms.headers
	}
}))(AppProvider)