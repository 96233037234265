// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";
import Avatar from 'react-avatar';

import './ThreadRow.css';

function ThreadRow({ location, ...props }) {
    var thread = props.thread || {};
    var thread_message = thread.text;

    var moment = Moment.globalMoment;
    if ( moment ) {
        moment.relativeTimeThreshold('d');
    }

    return (
        <Container fluid className={`ThreadContainer ${props.active?'active':''}`}>
            <div className="ImageColumn" style={{float:"left"}}>
                <Avatar className="ProfileImage" round size="48px" name={((thread.owner||{}).getName&&thread.owner.getName()) || thread.owner} src={((thread.owner||{}).getName&&thread.owner.getImage()) || (0&&'/img/IconPerson.svg') || ''} />
            </div>
            <Row className={`ThreadRow`}>
                <Col xs="12" className="ThreadColumn">
                    <p className="OwnerName compact">{((thread.owner||{}).getName&&thread.owner.getName()) || thread.owner}</p>
                    <p className="ThreadMessage compact">{thread_message}</p>
                    <p className="TimeValue compact small"><Moment format="MMM Do @ h:mma">{thread.created}</Moment></p>
                </Col>
            </Row>
        </Container>
	);
}

export default withRouter(ThreadRow);