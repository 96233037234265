// Bootstrap components
import { Container, Row, Col, /*Form, FormLabel, FormControl, Button, Alert*/ } from 'react-bootstrap';


//import React, { Component } from 'react';
import React from 'react';
//import Moment from 'react-moment';
import Helmet from 'react-helmet';
import { withRouter } from "react-router-dom";
//import AppComms from '../AppComms';

// Models
/*import Activity from '../shared/models/Activity.mjs';
import Individual from '../shared/models/Individual.mjs';
import PersonalInformation from '../shared/models/PersonalInformation.mjs';
import ContactInformation from '../shared/models/ContactInformation.mjs';
import DigitalAddress from '../shared/models/DigitalAddress.mjs';
import PhysicalAddress from '../shared/models/PhysicalAddress.mjs';
import Business from '../shared/models/Business.mjs';*/

import './Activities.css';

// Common components
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
//import SimplePanel from '../components/panels/SimplePanel';
//import ActivityRow from '../components/rows/ActivityRow';
import ActivityFragment from '../fragments/ActivityFragment';

class Activities extends React.Component {
	
    /*constructor(props) {
		super(props);
    }*/
    
	render(location) {
		return (
			<Content>
                <ScrollToTop/>
                <Helmet title="You Are Doing Great Profile" />

                <Container className="searchContainer">

                    <Row noGutters="true">
                        <Col sm="12">
                            <ActivityFragment context={this.props.context} title="Activity" />
                        </Col>
                    </Row>
                    
                </Container>
			</Content>
  	    );
    }
}

export default withRouter(Activities);