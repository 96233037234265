// Bootstrap components
import { Container, Row, Col } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import { withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";

import './ProcessPanel.css';

function ProcessPanel({ location, ...props }) {
	return (
		<div className={`ProcessPanelWrapper ${props.type}`}>
			<div className={`ProcessPanelInner`}>
				<Container fluid>
					<Row>
						<Col>
							<h1 className="title">{props.type}<br/>Cycle</h1>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="text">
								<img src="/img/CheckWhite.svg" width="14" height="14" alt="X" style={{"display": "inline-block", "verticalAlign": "top", "margin": "1px 4px 0 0"}} />
								Donor match<br/>
								<img src="/img/CheckWhite.svg" width="14" height="14" alt="X" style={{"display": "inline-block", "verticalAlign": "top", "margin": "1px 4px 0 0"}} />
								Legal complete
							</p>
						</Col>
						<Col>
							<h1 className="percent">{props.percent}%</h1>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default withRouter(ProcessPanel);