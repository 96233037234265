import React, { Component } from 'react';
import { Redirect, withRouter } from "react-router-dom";

import AppContext from '../AppContext';
//import AppProvider from '../AppProvider';
import './Logout.css';

//import AWS from 'aws-sdk';
//var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

class Logout extends Component {

  componentWillUnmount() {
    this.contextRef.logoutSession();
  }
  
  setContext(context) {
    this.contextRef = context;
    return '';
  }

	render(location) {

    /*var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.signOut();
    }*/

    //debugger;
    //AppProvider.logoutSession();

    return (
			<AppContext.Consumer>
				{context => (
          // Nothing to render... simply redirect to login page
          <div>
            {/*context.logoutSession()*/}
            {this.setContext(context)}
            <Redirect to="/login" />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default withRouter(Logout);