// Bootstrap components
import { Container, Row, Col, Form, FormLabel, FormControl, Button/*, Alert*/ } from 'react-bootstrap';


//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from "react-router-dom";

import './Onboarding.css';

// Common components
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import SimplePanel from '../components/panels/SimplePanel';

class Onboarding extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {validated: '', error: '', working: false, type: ''};
		this.form = React.createRef();
		this.typeUpdate = this.typeUpdate.bind(this);
		this.donorCycleUpdate = this.donorCycleUpdate.bind(this);
		this.ivfCycleUpdate = this.ivfCycleUpdate.bind(this);
		this.surrogacyCycleUpdate = this.surrogacyCycleUpdate.bind(this);
	}

	typeUpdate(event) {
		this.setState({type: event.target.value});
	}
	donorCycleUpdate(event) {
		this.setState({donor_cycle_started: event.target.value});
	}
	ivfCycleUpdate(event) {
		this.setState({ivf_cycle_started: event.target.value});
	}
	surrogacyCycleUpdate(event) {
		this.setState({surrogacy_cycle_started: event.target.value});
	}
	
	render(location) {
		return (
			<Content>
				<Container>
					<Row noGutters="true">
						<Col>
							<ScrollToTop/>
							<Helmet title="You Are Doing Great Profile" />

							<Form ref={this.form} noValidate validated={this.state.validated} onSubmit={this.completeActual.bind(this)}>
								<Container className="OnboardingContainer">
									<Row noGutters>
										<Col sm="12" md={{"span":"8", "offset":"2"}} lg={{"span":"6", "offset":"3"}}>
											<SimplePanel title="Let's get started...">
												<Container fluid>
													<Row>
														<Col>
															<p>We just need to ask a few questions to set everything up for you. Please answer as best as you can, and/or select the items that most closely describe your own personal circumstances.</p>
															<p>These answers are required to tailor your experience and the features we provide to your particular situation.</p>
															<Form.Row>
																<Form.Group as={Col} md="12" controlId="validationCustom01">
																	<FormLabel>I am an...</FormLabel>
																	<FormControl as="select"
																		required
																		value={this.state.type}
																		onChange={this.typeUpdate}>
																		<option value="">Select an option</option>
																		<option value="intended_parent">Intended parent</option>
																		<option value="surrogate">Surrogate</option>
																		<option value="oocyte_donor">Oocyte donor</option>
																		<option value="sperm_donor">Sperm donor</option>
																		<option value="doctor">Doctor</option>
																		<option value="nurse">Nurse</option>
																		<option value="other_medical">Other medical representative</option>
																		<option value="lawyer">Lawyer</option>
																		<option value="donor_agent">Donor agent</option>
																		<option value="surrogacy_agent">Surrogacy agent</option>
																		<option value="escrow_agent">Escrow agent</option>
																		<option value="other_legal">Other legal representative</option>
																		<option value="other_agency">Other agency representative</option>
																	</FormControl>
																</Form.Group>
															</Form.Row>
														</Col>
													</Row>
												</Container>
											</SimplePanel>
										</Col>
									</Row>

									<div hidden={['intended_parent'].indexOf(this.state.type) < 0}>
										<Row noGutters>
											<Col sm="12" md={{"span":"8", "offset":"2"}} lg={{"span":"6", "offset":"3"}}>
												<SimplePanel>
													<Container fluid>
														<Row>
															<Col>
																<p>We'd like to find out more about where you are in the process right now</p>
																<Form.Row>
																	<Form.Group as={Col} md="12" controlId="validationCustom01">
																		<FormLabel>Do you already have a sperm/egg donor (or intended donor)?</FormLabel>
																		<Form.Group controlId="groupDonorCycle">
																			<Form.Check as="select"
																				required
																				inline
																				type="radio"
																				name="groupDonorOption"
																				label="Yes"
																				value="yes"
																				onChange={this.donorCycleUpdate}>
																			</Form.Check>
																			<Form.Check as="select"
																				required
																				inline
																				type="radio"
																				name="groupDonorOption"
																				label="No"
																				value="no"
																				onChange={this.donorCycleUpdate}>
																			</Form.Check>
																		</Form.Group>
																	</Form.Group>
																</Form.Row>
																<Form.Row>
																	<Form.Group as={Col} md="12" controlId="validationCustom01">
																		<FormLabel>Have you already started working with a fertility clinic?</FormLabel>
																		<Form.Group controlId="groupIVFCycle">
																			<Form.Check as="select"
																				required
																				inline
																				type="radio"
																				name="groupIVFOption"
																				label="Yes"
																				value="yes"
																				onChange={this.ivfCycleUpdate}>
																			</Form.Check>
																			<Form.Check as="select"
																				required
																				inline
																				type="radio"
																				name="groupIVFOption"
																				label="No"
																				value="no"
																				onChange={this.ivfCycleUpdate}>
																			</Form.Check>
																		</Form.Group>
																	</Form.Group>
																</Form.Row>
																<Form.Row>
																	<Form.Group as={Col} md="12" controlId="validationCustom01">
																		<FormLabel>Have you already begun the surrogacy process?</FormLabel>
																		<Form.Group controlId="surrogacyIVFCycle">
																			<Form.Check as="select"
																				required
																				inline
																				type="radio"
																				name="groupSurrogacyOption"
																				label="Yes"
																				value="yes"
																				onChange={this.surrogacyCycleUpdate}>
																			</Form.Check>
																			<Form.Check as="select"
																				required
																				inline
																				type="radio"
																				name="groupSurrogacyOption"
																				label="No"
																				value="no"
																				onChange={this.surrogacyCycleUpdate}>
																			</Form.Check>
																		</Form.Group>
																	</Form.Group>
																</Form.Row>
															</Col>
														</Row>
													</Container>
												</SimplePanel>
											</Col>
										</Row>
							
										<div hidden={!(this.state.donor_cycle_started === "yes")}>
											<Row noGutters>
												<Col sm="12" md={{"span":"8", "offset":"2"}} lg={{"span":"6", "offset":"3"}}>
													<SimplePanel>
														<Container fluid>
															<Row>
																<Col>
																</Col>
															</Row>
														</Container>
													</SimplePanel>
												</Col>
											</Row>
										</div>
									</div>

									<Row noGutters>
										<Col sm="12" md={{"span":"8", "offset":"2"}} lg={{"span":"6", "offset":"3"}}>
											<SimplePanel>
												<Container fluid>
													<Row>
														<Col>
															<p>Thanks! Now let's get to the fun stuff...</p>
															<Form.Row>
																<Form.Group as={Col} md="12" controlId="validationCustom07" style={{'marginBottom':'8px'}}>
																	<Button type="submit" variant="primary" size="lg">Done</Button>
																</Form.Group>
															</Form.Row>
														</Col>
													</Row>
												</Container>
											</SimplePanel>
										</Col>
									</Row>

								</Container>
							</Form>

						</Col>
					</Row>
				</Container>
			</Content>
  		);
	}

	completeActual(event) {
		var that = this;
		//const form = event.currentTarget;
		
		event.preventDefault();
		event.stopPropagation();

		that&&that.props.history.push(`/dashboard`)
	}
}

export default withRouter(Onboarding);