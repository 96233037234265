//import React, { Component } from 'react';
import { connect } from 'react-refetch'

// Models
import Task from './shared/models/Task.mjs';
import Activity from './shared/models/Activity.mjs';
import Message from './shared/models/Message.mjs';
import Individual from './shared/models/Individual.mjs';
import PersonalInformation from './shared/models/PersonalInformation.mjs';
import ContactInformation from './shared/models/ContactInformation.mjs';
import DigitalAddress from './shared/models/DigitalAddress.mjs';
import PhysicalAddress from './shared/models/PhysicalAddress.mjs';
import Business from './shared/models/Business.mjs';

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

var API_BASE = "https://devapi.surrojoy.com/v1/";

// Override for local API
if ( window.location.hostname === 'localhost' ) {
	API_BASE = "http://localhost:3001/";
}

// Dummy handlers for server rendering
//if ( window && window.Request ) window.Request = function() { console.log('Dummy request handler'); debugger; return {data:{}}; };
//if ( global && !global.Request ) global.Request = function() { console.log('Dummy request handler'); debugger; return {data:{}}; };

class AppComms {
	// Define global class references
	static classes = {
		Task: Task,
		Activity: Activity,
		Message: Message,
		Individual: Individual,
		PersonalInformation: PersonalInformation,
		ContactInformation: ContactInformation,
		DigitalAddress: DigitalAddress,
		PhysicalAddress: PhysicalAddress,
		Business: Business
	}

	static headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'x-api-key': 'VvhX1naZJr9qp37yI8u6N7k8U6yLsn351ebupJIL',
	};

	static connect = ( window && window.Request )?connect.defaults({
		buildRequest: function (mapping) {
			const options = {
				method: mapping.method,
				cache: 'force-cache',
				//referrer: 'https://example.com',
				headers: mapping.headers,
				credentials: mapping.credentials,
				redirect: mapping.redirect,
				mode: mapping.mode,
				body: mapping.body
			}

			if (mapping.url.match(/private/)) {
				//options.headers['X-Api-Token'] = getPrivateToken()
				
				options.headers['Accept'] = this.headers['Accept'];
				options.headers['Content-Type'] = this.headers['Content-Type'];
				options.headers['x-api-key'] = this.headers['x-api-key'];
				options.headers['x-access-token'] = this.headers['x-access-token'];
			}
	
			//if (mapping.url.match(/listOfServers.json$/)) {
			//  options.integrity = 'sha256-BpfBw7ivV8q2jLiT13fxDYAe2tJllusRSZ273h2nFSE='
			//}
	
			if ( Request ) {
				return new Request(API_BASE + mapping.url, options)
			}
			return new Promise((resolve, reject) => {
				reject("Request unavailable");
			});
		},

		fetch: function(url, options) {
			if ( this.headers['x-access-token'] ) {
				return fetch(url, options);
			}
			return new Promise((resolve, reject) => {
				reject("Not authenticated");
			});
		}
	}):function() { return function(component) { console.log('Bypassing react-refetch...'); return component }; };

	static parseApiItems(items, existing) {
		// Create objects
		var objects = existing||{};
		Object.keys(items||[]).forEach(className => {
				items[className].forEach(item => {
						var classType = AppComms.classes[className];

						if ( classType ) {
							var obj = new classType(item);

							if ( obj ) {
									//obj.init(item);
									objects[item.id] = obj;
							}
						} else {
							throw(new Error("Unrecognised object"))
						}
				});
		});

		// Unpack objects (turn IDs into objects)
		Object.keys(objects).forEach(id => {
				objects[id].unpack(objects);
		});
		
		return objects;
	}







	static clearSession() {
		this.headers['x-access-token'] = null;
	}

	static async updateAccessToken() {
		var that = this;
		return new Promise(function(resolve, reject) {
			if ( !that.headers['x-access-token'] ) {
				var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
				var cognitoUser = userPool.getCurrentUser();
				if ( cognitoUser ) {
					cognitoUser.getSession(function(err, session) {
						if ( err ) reject(err);
						else {
							that.headers['x-access-token'] = session.getAccessToken().getJwtToken();
							resolve(true);
						}
					});
				} else {
					//reject(new Error('No user profile'));
					resolve(true);
				}
			} else {
				resolve(true);
			}
		});
	}

	static async get(endpoint) {
		var that = this;
		try {
			await this.updateAccessToken();
			var res = await fetch(API_BASE + endpoint, {
				method: 'GET',
				headers: that.headers,
				mode: 'cors',
				credentials: 'same-origin',
				cache: 'no-cache'
			})
			if ( res.ok ) {
				var data = await res.json();
				return data
			}
		} catch(e) {
			console.error(e);
		}
		return null;
	}

	static async post(endpoint, data) {
		var that = this;
		await this.updateAccessToken();
		var res = await fetch(API_BASE + endpoint, {
			method: 'POST',
			headers: that.headers,
			mode: 'cors',
			credentials: 'same-origin',
			cache: 'no-cache',
			body: JSON.stringify(data)
		})
		if ( res.ok ) {
			var output = await res.json();
			return output;
		}
		return null;
	}
}

AppComms.updateAccessToken();

export default AppComms;