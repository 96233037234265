// Bootstrap components
import { Container, Row, Col, FormLabel, FormControl, Button, Alert, Form } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { Link, withRouter } from "react-router-dom";
import AWS from 'aws-sdk';

import './Login.css';

// Models
/*import DigitalAddress from '../shared/models/DigitalAddress.mjs';
import ContactInformation from '../shared/models/ContactInformation.mjs';
import PersonalInformation from '../shared/models/PersonalInformation.mjs';
import Individual from '../shared/models/Individual.mjs';*/

// Common components
import AppContext from '../AppContext';
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import SimplePanel from '../components/panels/SimplePanel';

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

class Login extends React.Component {
	
  constructor(props) {
		super(props);
    this.state = {validated: '', error: '', working: false};
    this.contextRef = null;
    this.form = React.createRef();
    this.inputUsername = React.createRef();
    this.inputPassword = React.createRef();

    // Auto-check existing login
    this.existingLogin.bind(this)();
  }
  
  setContext(context) {
    this.contextRef = context;
    return '';
  }
	
	render(location) {
    
    /*var request = {
      sub: 'da766c72-a27a-447b-9fdd-09f75fb45640',
      'cognito:user_status': 'CONFIRMED',
      email_verified: 'true',
      'cognito:email_alias': 'karl@betterthanexpected.co',
      given_name: 'Karl',
      family_name: 'White',
      email: 'karl@betterthanexpected.co'
    };
		// Create the individual object
		var digital_address = new DigitalAddress({
      owner: request.sub,
		  type: "Email",
		  name: "Primary",
      value: request.email
    });
    console.log( digital_address.toSQLCreateString() );
    console.log( digital_address.toSQLUpdateString() );

    var contact_information = new ContactInformation({
      owner: request.sub,
      digital_addresses: digital_address.id
    });
    console.log( contact_information.toSQLCreateString() );
    console.log( contact_information.toSQLUpdateString() );

		var personal_information = new PersonalInformation({
      owner: request.sub,
		  first_name: request.given_name,
		  last_name: request.family_name,
      contact_information: contact_information.id
    });
    console.log( personal_information.toSQLCreateString() );
    console.log( personal_information.toSQLUpdateString() );

		var individual = new Individual({
      owner: request.sub,
      personal_information: personal_information.id
    });
    console.log( individual.toSQLCreateString() );
    console.log( individual.toSQLUpdateString() );
    debugger;*/
    

    return (
			<AppContext.Consumer>
				{context => (
          <Content>
            <ScrollToTop/>
            <Helmet title="You Are Doing Great Login" />
            {`${this.setContext(context)}`}

            <div hidden={!this.state.working}>
              <lottie-player src="https://assets3.lottiefiles.com/datafiles/5mEmfhAxpgrsoFG/data.json" background="transparent" speed="0.5" style={{"maxWidth":"100%","width":"300px","height":"75px","margin":"15vh auto 0"}} loop autoplay></lottie-player>
            </div>

            <Container hidden={this.state.working}>
              <Row noGutters="true">
                <Col sm="12" md={{"span":"8", "offset":"2"}} lg={{"span":"6", "offset":"3"}}>
                  <SimplePanel title="Log in">
                    <Container fluid>
                      <Alert show={!!this.state.error.length} variant="danger">
                        <p style={{marginBottom:0}}>{this.state.error}</p>
                      </Alert>
                      
                      <Form ref={this.form} noValidate validated={this.state.validated} onSubmit={this.loginActual.bind(this)} style={{'padding':'10px 0 10px'}}>
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="validationCustom04">
                            <FormLabel>Username</FormLabel>
                            <Form.Control
                              required
                              ref={this.inputUsername}
                              type="text"
                              autoComplete="username"
                              placeholder="Username"
                              aria-describedby="inputGroupPrepend"></Form.Control>
                            <Form.Control.Feedback type="invalid">Please enter your username.</Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="validationCustom05">
                            <FormLabel>Password</FormLabel>
                            <FormControl
                              required
                              ref={this.inputPassword}
                              type="password"
                              autoComplete="new-password"
                              placeholder="Password"></FormControl>
                            <Form.Control.Feedback type="invalid">Please enter your password.</Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="validationCustom07" style={{'marginBottom':'0'}}>
                            <Button type="submit" variant="primary" size="lg" style={{'margin':'16px 20px 0 0'}}>Log in</Button>
                            <p style={{"display":"inline-block","paddingTop":"16px","verticalAlign":"bottom"}}>New here? <Link to="/signup" className="primary">Create new account</Link></p>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    </Container>
                  </SimplePanel>
                </Col>
              </Row>
            </Container>
          </Content>
				)}
			</AppContext.Consumer>
    );
  }

  existingLogin() {
    var that = this;
    var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
        cognitoUser.getSession(function(err, session) {
            if (err) { console.log(err); return; }
            //console.log('session validity: ' + session.isValid());

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId : '...', // your identity pool id here
                Logins : {
                    // Change the key below according to the specific region your user pool is in.
                    'cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>' : session.getIdToken().getJwtToken()
                }
            });

            // Instantiate aws sdk service objects now that the credentials have been updated.
            // example: var s3 = new AWS.S3();
            if ( session.isValid() ) {
              that&&that.props.history.push(`/dashboard`)
            }
        });
    }
  }

	loginActual(event) {
    var that = this;
		var invalid = false;
		const form = event.currentTarget;
		
		event.preventDefault();
		event.stopPropagation();
		
		var username = this.inputUsername.current.value;
    var password = this.inputPassword.current.value;

		// Basic validation
    if (form.checkValidity() === false) {
			this.setState({validated: true});
			this.setState({error: 'Please correct the errors below'});
			invalid = true;
		}

		// Validate values
		if ( username.length < 4 ) {
			this.setState({error: 'Please enter a valid username'});
			invalid = true;
		}
		if ( password.length < 8 ) {
			this.setState({error: 'Please enter a valid password'});
			invalid = true;
		}
    
    if ( !invalid ) {

      that.setState({working: true});
      var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({ Username : username, Password : password });
      var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser({ Username : username, Pool : userPool });
      cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {
            //  var accessToken = result.getAccessToken().getJwtToken();
              
              /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer*/
            //  var idToken = result.idToken.jwtToken;

            if ( that.contextRef ) {
              setTimeout( function() {
                that.contextRef.restoreProfile(function(success) {
                  if ( success ) {
                    // Redirect to dash
                    that&&that.props.history.push(`/dashboard`)
                  } else {
                    that.setState({error: "Failed to obtain user session"});
                  }
                });
              }, 0);
            } else {
              that.setState({error: "Context unavailable"});
            }
            //that.setState({working: false});
          },
          onFailure: function(err) {
            if ( err.code === "UserNotConfirmedException" ) {
              // User unconfirmed
              that.setState({error: "Your account is not confirmed. Please check your email inbox for a confirmation link."});
            } else if (err.code === 'NotAuthorizedException'){
              // Not Authorised (Incorrect Password)
              that.setState({error: "Invalid login credentials"});
            } else if (err.code === 'ResourceNotFoundException'){
                // User Not found
                that.setState({error: "Unknown user"});
            } else {
              that.setState({error: err.message});
            }
            that.setState({working: false});
          },
      });
    }
  }
}

export default withRouter(Login);