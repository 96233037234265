import React from 'react';

import styled from "styled-components";

// Common components
import './Common.css';

// Dynamic styling
const WrapperDiv = styled.div``;
const ContentDiv = styled.div``;

export function Wrapper({ location, ...props }) {
	return (
		<WrapperDiv className="Wrapper">
			{props.children}
		</WrapperDiv>
	)
}
export function Content({ location, ...props }) {
	return (
		<ContentDiv className="Content">
			{props.children}
		</ContentDiv>
	)
}