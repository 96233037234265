// Bootstrap components
import { Container, Row, Col, Alert } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from "react-router-dom";

// Common components
import AppContext from '../AppContext';
import './Unconfirmed.css';
import { Content } from '../common/Common';
import ScrollToTop from '../common/ScrollToTop';
import SimplePanel from '../components/panels/SimplePanel';

//import AWS from 'aws-sdk';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

class Unconfirmed extends React.Component {
	
  constructor(props) {
		super(props);
    this.state = {error: '', working: true};

		this.sessionGate.bind(this)();
	}
	
	render(location) {
    return (
			<AppContext.Consumer>
				{context => (
          <Content>
            <ScrollToTop/>
            <Helmet title="You Are Doing Great Login" />

            <Container>
              <Row noGutters="true">
                <Col sm="12" md={{"span":"8", "offset":"2"}} lg={{"span":"6", "offset":"3"}}>
                  <SimplePanel title="Log in">
                    <Container fluid>
                      <Alert show={!!this.state.error.length} variant="danger">
                        <p style={{marginBottom:0}}>{this.state.error}</p>
                      </Alert>

											Awaiting confirmation...

											<div hidden={!this.state.working}>
												<lottie-player src="https://assets3.lottiefiles.com/datafiles/5mEmfhAxpgrsoFG/data.json" background="transparent" speed="0.5" style={{"maxWidth":"100%","width":"300px","height":"75px","margin-bottom":"100px"}} loop autoplay></lottie-player>
											</div>
                    </Container>
                  </SimplePanel>
                </Col>
              </Row>
            </Container>
          </Content>
				)}
			</AppContext.Consumer>
    );
	}

	sessionGate() {
		var that = this;
		var userPool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId : 'us-west-2_02n8IjIvr', ClientId : '6pvoa322gs8tj47ggamu8krv4v' });
		var cognitoUser = userPool.getCurrentUser();

		if (cognitoUser == null) {
			that&&that.props.history.push(`/login`)
		} else {
			cognitoUser.getSession(function(err, session) {
				debugger;
				if (err) {
					if ( err.code === "UserNotConfirmedException" ) {
						that&&that.props.history.push(`/unconfirmed`); 
					} else {
						that&&that.props.history.push(`/login`); 
					}
				} else if ( !session.isValid() ) {
					that&&that.props.history.push(`/login`)
				}
			});
		}
	}
};

export default withRouter(Unconfirmed);