// Bootstrap components
//import { Row, Col } from 'react-bootstrap';


//import React, { Component } from 'react';
import React from 'react';
import Moment from 'react-moment';
import { withRouter } from "react-router-dom";

import './ActivityFragment.css';

// Common components
import AppComms from '../AppComms';
import SimplePanel from '../components/panels/SimplePanel';
import ActivityRow from '../components/rows/ActivityRow';

class ActivityFragment extends React.Component {
    static activityCache = null;
    
    constructor(props) {
        super(props);

        // Restore state for this session
        this.state = {
            activityObjects: ActivityFragment.activityCache||{}
        };

        this.form = React.createRef();
    }
    
    async componentDidMount() {
    }
	componentWillUnmount() {
        // Save state for current session
        ActivityFragment.activityCache = this.state.activityObjects;
    }
    async componentDidUpdate(prevProps) {
        if ( this.props.activityFetch !== prevProps.activityFetch ) {
            if (this.props.activityFetch.pending) {
            } else if (this.props.activityFetch.rejected) {
                console.error(`${this.props.activityFetch.meta.request.url} ${this.props.activityFetch.reason}`);
                this.setState({activityObjects: {}});
                debugger;
            } else if (this.props.activityFetch.fulfilled) {
                var activityObjects = AppComms.parseApiItems(this.props.activityFetch.value.items, this.state.activityObjects);
                this.setState({activityObjects});
                this.forceUpdate();
            }
        }
    }
    activityCount(activityObjects) {
        return Object.values(activityObjects||{}).filter(obj => obj.tableName() === "Activity").length;
    }
    activityTimeline(activityObjects) {
        var activityArray = Object.values(activityObjects||{}).filter(obj => obj.tableName() === "Activity");
        return this.searchBucket(activityArray);
    }
    
    render(location, ...props) {
        return (
            <SimplePanel title={this.props.title}>

                <div hidden={(!this.activityCount(this.state.activityObjects))}>
                    {Object.keys(this.activityTimeline(this.state.activityObjects)).sort((a, b) => {
                        return new Date(b).getTime() - new Date(a).getTime()
                    }).map(bucket => {
                        return (
                            <div key={bucket} className="activityBucket">
                                <h4 className="activityBucketTitle primary" style={{margin:"12px 0 0"}}>
                                    {bucket === new Date().toLocaleDateString() ? "Today" :
                                        <Moment format="MMM Do YYYY">{new Date(bucket)}</Moment>
                                    }
                                </h4>

                                {(this.activityTimeline(this.state.activityObjects))[bucket].map(item => 
                                    <ActivityRow key={item.id} activity={item}/>
                                )}
                            </div>
                        )}
                    )}
                </div>
                
                <div hidden={this.activityCount(this.state.activityObjects)} style={{'textAlign': 'left'}}>
                    <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_lmaNPc.json" background="transparent" speed="0.5" style={{"width": "50%", "maxWidth": "320px", "transformOrigin": "top left", "transform": "scaleX(2)"}} loop autoplay></lottie-player>
                </div>

                {this.props.children}

            </SimplePanel>
        );
    }
    
    searchBucket(activities) {
        var activityTimeline = {};
        activities.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        }).slice(0, parseInt(this.props.limit||"0")||undefined).forEach(activity => {
            var bucketName = activity.created.toLocaleDateString();
            activityTimeline[bucketName] = activityTimeline[bucketName]||[];
            activityTimeline[bucketName].push(activity);
        });
        return activityTimeline;
    }
}

export default withRouter(AppComms.connect(props => ({
    activityFetch: {
        url: `activity`,
        headers: AppComms.headers
    }
}))(ActivityFragment))