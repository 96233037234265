// Bootstrap components
import { Container, Row, Col, Button } from 'react-bootstrap';

//import React, { Component } from 'react';
import React from 'react';
import { withRouter } from "react-router-dom";
//import { TransitionGroup, CSSTransition } from "react-transition-group";

import './CompanyPanel.css';

function CompanyPanel({ location, ...props }) {
    var business = props.business || {};
    var business_type = business && business.getDisplayType();
    var contact = business.contact_information || {};
    var address = (contact.physical_addresses||[]).length && contact.physical_addresses[0];
    var display_address = address && address.getDisplayAddress();
    var phone = (contact.digital_addresses||[]).length && contact.digital_addresses[0];
    var display_phone = phone && phone.getDisplayPhone();
	return (
		<div className={`CompanyPanelWrapper`}>
			<div className={`CompanyPanelInner`}>
				<Container>
					<Row className="childrow">
                        <Col xs="4">
                            <a href={business.external_link} target="_blank" rel="noopener noreferrer">
                                <div className="ImageContainer" style={{backgroundImage: `url("/img/ImagePlaceholder.svg")`, background:`url(${business.image_url || "/img/ImagePlaceholder.svg"}) center center/cover no-repeat`}}>
                                    {business.external_source === "yelp" &&
                                        <img src="/img/BadgeYelp.svg" className="sourceBadge" alt="Yelp" title="This search result provided by Yelp"/>
                                    }
                                </div>
                            </a>
                        </Col>
						<Col xs="8" className="detailPane">
                            <Container fluid>
                                <a href={business.external_link} target="_blank" rel="noopener noreferrer">
                                    <Row noGutters className="titlerow">
                                        <Col sm="12">
                                            <h4>{business.name}</h4>
                                            <p>{business_type}</p>
                                        </Col>
                                    </Row>
                                </a>
                                <Row noGutters className="detailrow">
                                    <Col sm="12">
                                        <p>{display_address}</p>
                                        <p>{display_phone}</p>
                                    </Col>
                                </Row>
                                <Row noGutters className="actionrow">
                                    <Col sm="12" className="actioncolumn">
                                        <a href={`callto:${phone.value}`}><Button variant="secondary" size="sm"><span style={{"fontFamily":"dingbats, arial unicode ms, code2000, sans-serif"}}>&#9990;</span> Call</Button></a>
                                        <Button variant="primary" size="sm">Use company</Button>
                                    </Col>
                                </Row>
                            </Container>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default withRouter(CompanyPanel);